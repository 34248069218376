import { useCallback } from "react";
import { BulkUploadResult, PlantMaterial, SupplierMaterial } from "../types";
import { useApi } from "../useApi";

export const useGetPlantMaterials = () => {
  const api = useApi<PlantMaterial[]>({ method: "GET" });

  return useCallback(
    ({
      manufacturerId,
      plantId,
      type,
    }: {
      manufacturerId: string;
      plantId: string;
      type?: "raw_materials" | "packaging";
    }) => {
      const searchParams = new URLSearchParams();

      if (type) {
        searchParams.set("type", type);
      }

      return api({
        url: `/v1/manufacturers/${manufacturerId}/plants/${plantId}/inputs?${searchParams.toString()}`,
      });
    },
    [api],
  );
};

export const useGetMaterial = () => {
  const api = useApi<SupplierMaterial>({ method: "GET" });

  return useCallback(
    ({
      manufacturerId,
      plantId,
      materialId,
    }: {
      manufacturerId: string;
      plantId: string;
      materialId: string;
    }) =>
      api({
        url: `/v1/manufacturers/${manufacturerId}/plants/${plantId}/supplier-products/${materialId}`,
      }),
    [api],
  );
};

export const usePostMaterials = () => {
  const api = useApi<SupplierMaterial>({ method: "POST" });

  return useCallback(
    ({
      manufacturerId,
      plantId,
      material,
    }: {
      manufacturerId: string;
      plantId: string;
      material: Omit<SupplierMaterial, "id" | "epd_id" | "secondary_data"> & {
        epd_id: string | null;
      };
    }) =>
      api({
        url: `/v1/manufacturers/${manufacturerId}/plants/${plantId}/supplier-products`,
        data: material,
      }),
    [api],
  );
};

export const usePutMaterial = () => {
  const api = useApi<SupplierMaterial>({ method: "PUT" });

  return useCallback(
    ({
      manufacturerId,
      plantId,
      material,
    }: {
      manufacturerId: string;
      plantId: string;
      material: Omit<SupplierMaterial, "epd_id" | "secondary_data"> & {
        epd_id: string | null;
      };
    }) =>
      api({
        url: `/v1/manufacturers/${manufacturerId}/plants/${plantId}/supplier-products/${material.id}`,
        data: material,
      }),
    [api],
  );
};

export const useBulkUploadMaterials = () => {
  const api = useApi<BulkUploadResult>({
    method: "POST",
    headers: { "Content-Type": "multipart/form-data" },
  });

  return useCallback(
    ({
      manufacturerId,
      plantId,
      file,
    }: {
      manufacturerId: string;
      plantId: string;
      file: File;
    }) => {
      const formData = new FormData();
      formData.append("file", file);

      return api({
        url: `/v1/manufacturers/${manufacturerId}/plants/${plantId}/supplier-products/bulk`,
        data: formData,
      });
    },
    [api],
  );
};

export const useDeleteMaterial = () => {
  const api = useApi<SupplierMaterial>({ method: "DELETE" });

  return useCallback(
    ({
      manufacturerId,
      plantId,
      materialId,
    }: {
      manufacturerId: string;
      plantId: string;
      materialId: string;
    }) =>
      api({
        url: `/v1/manufacturers/${manufacturerId}/plants/${plantId}/supplier-products/${materialId}`,
      }),
    [api],
  );
};
