import { FileDownloadOutlined } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { useCallback, useState } from "react";
import { FileTrigger } from "react-aria-components";
import { useHotkeys } from "react-hotkeys-hook";
import { useTranslation } from "react-i18next";
import { useDownloadDocument, useUploadDocument } from "../../api/endpoints/declarations";
import { Declaration, Product } from "../../api/types";
import { Button } from "../../components/Button";
import { InfoTooltip } from "../../components/InfoTooltip";
import { SidePanel } from "../../components/SidePanel";
import { Label12, Text14, Text16 } from "../../components/Typography";
import { HARD_CODED_PROGRAM_OPERATION_IMAGES } from "../../lib/programOperator";
import { useAddDeclaration, useDeclaration } from "../../state/declarations";
import { useProductCategories } from "../../state/manufacturers";
import { useVerifyProduct } from "../../state/products";
import { SelectProgramOperatorForm } from "./SelectProgramOperatorForm";

export const ProductDetailSidePanel = ({
  open,
  setOpen,
  product,
}: {
  open: boolean;
  setOpen: (value: boolean) => void;
  product: Product;
}) => {
  const { data: declaration } = useDeclaration({
    productId: product.id,
    plantId: product.plant_id,
    manufacturerId: product.manufacturer_id,
  });
  const [adminMode, setAdminMode] = useState(false);

  const toggleAdminMode = useCallback(() => setAdminMode((prev) => !prev), []);
  useHotkeys("ctrl+shift+e", toggleAdminMode, [toggleAdminMode]);

  const [regenerating, setRegenerating] = useState(false);
  const onClose = () => {
    setOpen(false);
    setRegenerating(false);
  };
  const { t } = useTranslation();
  const { mutate: addDeclaration, isPending: loading } = useAddDeclaration({
    product,
  });

  const { mutate: verifyProduct, isPending: verificationLoading } = useVerifyProduct();
  const onVerify = () => {
    if (!product) return;

    verifyProduct({
      manufacturerId: product.manufacturer_id,
      plantId: product.plant_id,
      productId: product.id,
    });
  };

  const { mutate: uploadDocument } = useUploadDocument();
  const handleUpload = (files: FileList | null) => {
    const file = files?.[0];
    if (!file || !declaration) return;
    const token = prompt("Enter emidat token");
    if (!token) return;

    uploadDocument({
      manufacturerId: product.manufacturer_id,
      plantId: product.plant_id,
      productId: product.id,
      declarationId: declaration.id,
      token,
      file,
    });
  };

  const { productCategoriesMap } = useProductCategories();
  const programOperatorAvailability = productCategoriesMap[product.category.id]?.po_availability;
  const currentPOA = programOperatorAvailability?.find(
    (poa) => poa.po.id === declaration?.program_operator_id,
  );

  const verifying = product.status === "under-review";
  const verified = product.status === "completed";

  return (
    <SidePanel title={t("EPD Documents")} open={open} onClose={onClose}>
      {(regenerating || !declaration) && (
        <div className="space-y-4">
          <Label12 className="flex items-center gap-2">
            {t("Choose your program operator")}
            <InfoTooltip>
              {t(
                "An EPD Program Operator (PO) is an independent organization that administers the process of developing, verifying, and publishing Environmental Product Declarations. POs play a critical role in the environmental assessment landscape by ensuring that EPDs are accurate, standardized, and publicly available, thereby promoting sustainable product development and informed decision-making.",
              )}
            </InfoTooltip>
          </Label12>
          <SelectProgramOperatorForm
            editable={adminMode}
            product={product}
            loading={loading}
            onSubmit={(operatorId) =>
              addDeclaration(operatorId, {
                onSuccess: () => {
                  if (regenerating) {
                    setRegenerating(false);
                  }
                },
              })
            }
          />
        </div>
      )}
      {!regenerating && declaration && (
        <div className="space-y-16">
          <div className="space-y-4">
            <Label12>{t("Documents")}</Label12>
            <div className="flex flex-col divide-y divide-neutral-300 border-t border-b border-neutral-300">
              {declaration.result_documents
                .filter((x) => adminMode || !x.document.emidat_only)
                .map((x) => x.document)
                .map((doc) => (
                  <div key={doc.name} className="grid grid-cols-[1fr_auto] items-center gap-2 py-2">
                    <Text16 className="font-bold">{doc.name}</Text16>
                    <DownloadDocumentButton doc={doc} product={product} />
                  </div>
                ))}
            </div>
            {adminMode && (
              <FileTrigger onSelect={handleUpload}>
                <Button intent="secondary">{t("Upload Document")}</Button>
              </FileTrigger>
            )}
            {!verified && !verifying && (
              <Button intent="secondary" onPress={() => setRegenerating(true)}>
                {t("Re-Generate EPD Documents")}
              </Button>
            )}
          </div>
          {!verified && (
            <div className="space-y-4">
              <Label12>{t("Publish EPD")}</Label12>
              <hr />
              {currentPOA && (
                <div className="flex items-center gap-4">
                  <div className="flex items-center justify-center overflow-hidden h-12 w-12 border">
                    <img
                      className={`w-full h-full object-contain`}
                      src={HARD_CODED_PROGRAM_OPERATION_IMAGES[currentPOA.po.id]}
                    />
                  </div>
                  <div>
                    <Text16 className={`font-bold`}>{currentPOA.po.name}</Text16>
                    {currentPOA.available && (
                      <Text14>
                        {t("Verification time")}: {currentPOA.verification_time}
                      </Text14>
                    )}
                  </div>
                </div>
              )}
              <hr />
              <Button
                intent="primary"
                onPress={onVerify}
                isDisabled={verifying || verificationLoading}
              >
                {verificationLoading && <CircularProgress size="24px" />}
                {verifying ? t("Verification in process") : t("Verify & publish")}
              </Button>
            </div>
          )}
        </div>
      )}
    </SidePanel>
  );
};

const DownloadDocumentButton = ({
  doc,
  product,
}: {
  doc: Declaration["result_documents"][0]["document"];
  product: Product;
}) => {
  const { mutate: downloadDocument, isPending } = useDownloadDocument();

  return (
    <Button
      intent="tertiary"
      isDisabled={isPending}
      onPress={() =>
        downloadDocument({
          documentId: doc.id,
          documentName: doc.name,
          manufacturerId: product.manufacturer_id,
          plantId: product.plant_id,
          productId: product.id,
        })
      }
    >
      <Text14 className="font-bold text-steelblue">Download</Text14>
      {isPending ? (
        <CircularProgress size="24px" />
      ) : (
        <FileDownloadOutlined className="text-steelblue" />
      )}
    </Button>
  );
};
