import { ArrowBack, DeleteOutlined } from "@mui/icons-material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Elementary, SupplierMaterial } from "../../../api/types";
import { Button } from "../../../components/Button";
import { ConfirmationModal } from "../../../components/ConfirmationModal";
import { PageContainer } from "../../../components/PageContainer";
import { TopBar } from "../../../components/TopBar";
import { Text16 } from "../../../components/Typography";
import { TextFieldConnected } from "../../../form-components/TextFieldConnected";
import { useElementaries } from "../../../state/elementaries";
import { useAddMaterial, useMaterials, useUpdateMaterial } from "../../../state/materials";
import { useActivePlant } from "../../../state/plants";
import {
  useAddSupplier,
  useAddSupplierPlant,
  useSupplierPlants,
  useSuppliers,
} from "../../../state/suppliers";
import { showSuccessToast } from "../../../util/toasts";
import { LinkEnvironmentalData } from "./LinkEnvironmentalData";
import { MaterialBasics } from "./MaterialBasics";
import { SupplyRoute } from "./SupplyRoute";
import { FormFields } from "./types";
import { useMaterialsDeletion } from "./useMaterialsDeletion";

// TODO: Get the complexity down by extracting some of the functions
// eslint-disable-next-line complexity
export const Form = ({
  existingMaterial,
  type,
}: {
  existingMaterial: SupplierMaterial | null;
  type?: Elementary["type"];
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { deletionStaged, setDeletionStaged, removeMaterials } = useMaterialsDeletion();

  const { data: suppliers } = useSuppliers();
  const activePlant = useActivePlant();
  const { elementariesMap } = useElementaries();

  const { rawMaterials, packagingMaterials } = useMaterials();

  const [supplierId, setSupplierId] = useState<string | undefined>(
    existingMaterial?.supplier_plant?.supplier_company_id,
  );
  const { data: supplierPlants = [] } = useSupplierPlants(supplierId);
  const [hasSupplyRoute, setHasSupplyRoute] = useState<boolean>(
    existingMaterial?.elementary_id
      ? elementariesMap[existingMaterial.elementary_id].category.toLowerCase() !== "water"
      : false,
  );

  const defaultTransportation = useMemo(() => [{ modality: "truck", distance: null }], []);

  const methods = useForm<FormFields>({
    defaultValues: {
      elementaryId: existingMaterial?.elementary_id || "",
      supplierCompanyName: existingMaterial?.supplier_plant?.supplier_company.name || "",
      productName: existingMaterial?.name || "",
      supplierPlantName: existingMaterial?.supplier_plant?.name || "",
      supplierPlantCountry: existingMaterial?.supplier_plant?.country || "",
      transportation: existingMaterial?.transportation || defaultTransportation,
      epdId: existingMaterial?.epd_id || null,
    },
  });

  const onElementaryChange = useCallback(() => {
    const elementaryId = methods.getValues("elementaryId");
    const elementaryCategory = elementaryId ? elementariesMap[elementaryId].category : null;

    if (elementaryCategory && elementaryCategory.toLowerCase() === "water") {
      setHasSupplyRoute(false);
      methods.setValue("transportation", []);
      methods.setValue("supplierPlantName", "");
      methods.setValue("supplierCompanyName", "");
      methods.setValue("supplierPlantCountry", "");
    } else {
      setHasSupplyRoute((prev) => {
        if (prev === false) {
          methods.setValue("transportation", defaultTransportation);
        }
        return true;
      });
    }
  }, [methods, elementariesMap, defaultTransportation]);

  const onSupplierCompanyChange = useCallback(() => {
    methods.setValue("supplierPlantName", "");
    methods.setValue("supplierPlantCountry", "");
    methods.setValue("transportation", defaultTransportation);
  }, [methods, defaultTransportation]);

  const onSupplierPlantChange = useCallback(() => {
    const supplierPlantName = methods.getValues("supplierPlantName");
    const existingSupplierPlant = supplierPlants?.find((s) => s.name === supplierPlantName);

    if (!existingSupplierPlant) {
      methods.setValue("supplierPlantCountry", "");
      methods.setValue("transportation", defaultTransportation);
      return;
    }

    methods.setValue("supplierPlantCountry", existingSupplierPlant.country);

    const plantMaterials = [...rawMaterials, ...packagingMaterials]
      .filter((x) => x.supplier_product)
      .map((x) => x.supplier_product)
      .filter((mat) => mat.supplier_plant_id === existingSupplierPlant.id);

    if (plantMaterials.length > 0) {
      const mostRecentMaterial = plantMaterials.at(-1)!;
      methods.setValue("transportation", mostRecentMaterial.transportation);
    } else {
      methods.setValue("transportation", defaultTransportation);
    }
  }, [methods, supplierPlants, rawMaterials, packagingMaterials, defaultTransportation]);

  useEffect(() => {
    const subscription = methods.watch((_, { name }) => {
      if (name === "elementaryId") {
        onElementaryChange();
      } else if (name === "supplierCompanyName") {
        onSupplierCompanyChange();
      } else if (name === "supplierPlantName") {
        onSupplierPlantChange();
      }
    });
    return () => subscription.unsubscribe();
  }, [methods, onElementaryChange, onSupplierCompanyChange, onSupplierPlantChange]);

  const supplierCompanyName = methods.watch("supplierCompanyName");
  const supplierPlantName = methods.getValues("supplierPlantName");

  useEffect(() => {
    const existingSupplier = suppliers.find((s) => s.name === supplierCompanyName);

    if (existingSupplier) {
      setSupplierId(existingSupplier.id);
    } else {
      setSupplierId(undefined);
    }
  }, [supplierCompanyName, suppliers]);

  const { mutate: addSupplier } = useAddSupplier();
  const { mutate: addSupplierPlant } = useAddSupplierPlant();
  const { mutate: addMaterial } = useAddMaterial();
  const { mutate: updateMaterial } = useUpdateMaterial();

  const onSubmit = async (fields: FormFields) => {
    if (!activePlant) return;

    try {
      const materialHasSupplier =
        fields.supplierCompanyName && fields.supplierCompanyName.length > 0;

      let supplier = suppliers.find((s) => s.name === fields.supplierCompanyName);

      if (materialHasSupplier && !supplier) {
        supplier = await new Promise((resolve) =>
          addSupplier({ name: fields.supplierCompanyName }, { onSuccess: (data) => resolve(data) }),
        );
      }

      let supplierPlant = supplierPlants.find((s) => s.name === fields.supplierPlantName);

      if (materialHasSupplier && supplier && !supplierPlant) {
        supplierPlant = await new Promise((resolve) =>
          addSupplierPlant(
            {
              supplierId: supplier.id,
              supplierPlant: {
                name: fields.supplierPlantName,
                country: fields.supplierPlantCountry,
              },
            },
            {
              onSuccess: (data) => resolve(data),
            },
          ),
        );
      }

      const material = {
        id: existingMaterial?.id,
        name: fields.productName,
        elementary_id: fields.elementaryId,
        supplier_plant_id: materialHasSupplier ? (supplierPlant?.id ?? null) : null,
        plant_id: activePlant.id,
        epd_id: fields.epdId || null,
        transportation: fields.transportation.map((t) => ({
          ...t,
          load: null,
          utilization: null,
          distance: t.distance ?? 0,
        })),
      };

      if (material.id) {
        updateMaterial(
          {
            material: { ...material, id: material.id },
          },
          {
            onSuccess: () => {
              navigate("/suppliers-and-materials");
              showSuccessToast(t("Material changed"));
            },
          },
        );
      } else {
        addMaterial(
          { material },
          {
            onSuccess: () => {
              navigate("/suppliers-and-materials");
              showSuccessToast(t("New material created"));
            },
          },
        );
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <FormProvider {...methods}>
      <PageContainer $as="form" onSubmit={methods.handleSubmit(onSubmit)} noValidate>
        <TopBar
          title={existingMaterial ? t("Edit material") : t("Add new material")}
          icon={<ArrowBack />}
          onIconClick={() => navigate("/suppliers-and-materials")}
          input={
            existingMaterial ? (
              <>
                <Button type="button" onPress={() => setDeletionStaged(true)} intent="tertiary">
                  {t("Delete material")}
                  <DeleteOutlined />
                </Button>
                <Button type="submit" intent="primary">
                  {t("Update material")}
                </Button>
              </>
            ) : (
              <Button type="submit">{t("Save Material")}</Button>
            )
          }
        />
        <div className="flex flex-col my-8 h-full">
          <TextFieldConnected
            name="productName"
            isRequired
            placeholder="Product Name"
            size="large"
            label={t("Product Name")}
          />
          <div className="grid grid-cols-2 h-full divide-x">
            <div className="flex flex-col gap-6 py-6 pr-6">
              <MaterialBasics existingMaterial={existingMaterial} type={type} />
              {hasSupplyRoute && (
                <SupplyRoute
                  supplierPlants={supplierPlants}
                  suppliers={suppliers}
                  supplierPlantExists={!!supplierPlants.find((s) => s.name === supplierPlantName)}
                />
              )}
            </div>
            <div className="flex flex-col gap-6 py-6 pl-6">
              <LinkEnvironmentalData existingMaterial={existingMaterial} />
            </div>
          </div>
        </div>
        {deletionStaged && existingMaterial && (
          <ConfirmationModal
            title={t("Delete material")}
            content={<Text16>{t("Confirm the deletion")}</Text16>}
            danger
            onConfirm={() => removeMaterials([existingMaterial.id])}
            onCancel={() => setDeletionStaged(false)}
          />
        )}
      </PageContainer>
    </FormProvider>
  );
};
