import { Suspense, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import tw from "tailwind-styled-components";
import { useProductionProcesses } from "../../api/endpoints/production-processes";
import { Plant, Product, ProductCategory, ProductionProcess } from "../../api/types";
import { Heading3, Text16 } from "../../components/Typography";
import { RenderedFlow } from "../../page-components/product-detail/RenderedFlow";
import { CATEGORIES_WITHOUT_RECIPE } from "../../state/HARD_CODED";
import { useProductCategories } from "../../state/manufacturers";
import { useActivePlant } from "../../state/plants";
import { useSelectedProduct, useUpdateProduct } from "../../state/products";
import { useSplash } from "../../state/splash";
import { useGetLinkWithParams } from "../../url/useGetLinkWithParams";
import { EditFlowNav } from "./EditFlowNav";

const useSubmitProductionProcess = ({
  activePlant,
  selectedProduct,
  selectedProcessId,
}: {
  activePlant?: Plant;
  selectedProduct: Product;
  selectedProcessId?: string;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { mutate: updateProduct, isPending: loading } = useUpdateProduct();
  const [searchParams] = useSearchParams();
  const { setSplash } = useSplash();

  const onSubmit = useCallback(() => {
    if (!activePlant || !selectedProcessId) return;

    updateProduct(
      {
        productId: selectedProduct.id,
        product: {
          ...selectedProduct,
          production_process_id: selectedProcessId,
        },
      },
      {
        onSuccess: () => {
          if (CATEGORIES_WITHOUT_RECIPE.includes(selectedProduct.category.id)) {
            navigate({ pathname: `/products/${selectedProduct.id}` });
            setSplash({ message: t("New product created") });
          } else {
            navigate({ pathname: "/edit/product-recipe", search: searchParams.toString() });
          }
        },
      },
    );
  }, [
    activePlant,
    navigate,
    searchParams,
    selectedProcessId,
    selectedProduct,
    setSplash,
    t,
    updateProduct,
  ]);

  return {
    loading,
    onSubmit,
  };
};

export const ProductProductionProcess = () => {
  const { data: selectedProduct } = useSelectedProduct();
  const activePlant = useActivePlant();

  if (!selectedProduct) return null;

  return (
    <Suspense>
      <ProductProductionProcessForm selectedProduct={selectedProduct} activePlant={activePlant} />
    </Suspense>
  );
};

const ProductProductionProcessForm = ({
  selectedProduct,
  activePlant,
}: {
  selectedProduct: Product;
  activePlant: Plant;
}) => {
  const navigate = useNavigate();
  const getLinkWithParams = useGetLinkWithParams();
  const onPrev = () => navigate(getLinkWithParams("/edit/product-specs"));

  const [selectedProcessId, setSelectedProcessId] = useState<string>();

  const { productCategoriesMap } = useProductCategories();
  const { data: productionProcesses } = useProductionProcesses({ plantId: activePlant.id });

  const { loading, onSubmit } = useSubmitProductionProcess({
    activePlant,
    selectedProduct,
    selectedProcessId,
  });

  const productionProcssesOfCategory = productionProcesses.filter(
    (pp) => pp.category_id === selectedProduct.category.id,
  );

  return (
    <div className="flex-grow flex flex-col gap-8 overflow-hidden mx-auto w-full max-w-6xl pb-20">
      <form
        className="flex-grow flex flex-col gap-5 overflow-hidden"
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <div className="flex-grow flex flex-col gap-5 overflow-scroll p-2">
          {productionProcssesOfCategory.map((process) => (
            <ProductionProcessItem
              key={process.id}
              item={process}
              category={productCategoriesMap[process.category_id]}
              isActive={process.id === selectedProcessId}
              isDisabled={
                !process.input_output_per_year?.some(
                  (io) => io.year === selectedProduct.year_under_review,
                )
              }
              onSelectItem={setSelectedProcessId}
            />
          ))}
        </div>
        <EditFlowNav
          onPrev={onPrev}
          nextSubmit
          nextLoading={loading}
          nextDisabled={!selectedProcessId}
        />
      </form>
    </div>
  );
};

const ItemContainer = tw("div")<{ $active?: boolean; $disabled?: boolean }>`
  bg-white
  rounded-lg
  p-8
  border-2
  hover:shadow
  hover:-translate-y-[1px]
  active:translate-y-0
  ${({ $active }) => $active && "border-energyyellow"}
  ${({ $disabled }) =>
    $disabled
      ? "opacity-70 cursor-not-allowed hover:shadow-none"
      : "cursor-pointer hover:border-energyyellow"}
`;

const ProductionProcessItem = ({
  item,
  category,
  isActive,
  isDisabled,
  onSelectItem,
}: {
  item: ProductionProcess;
  category?: ProductCategory;
  isActive: boolean;
  isDisabled: boolean;
  onSelectItem: (id: string) => void;
}) => {
  return (
    <ItemContainer
      $active={isActive}
      $disabled={isDisabled}
      onClick={isDisabled ? undefined : () => onSelectItem(item.id)}
    >
      <Heading3>{item.name}</Heading3>
      <Text16>{category?.name.en}</Text16>
      <RenderedFlow flow={item} />
    </ItemContainer>
  );
};
