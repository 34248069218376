import { useMutation, useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import {
  useGetMaterial,
  useGetPlantMaterials,
  usePostMaterials,
  usePutMaterial,
} from "../api/endpoints/materials";
import { SupplierMaterial } from "../api/types";
import { useActiveManufacturer } from "./manufacturers";
import { useActivePlant } from "./plants";

export const useMaterial = (id?: string) => {
  const { activeManufacturer } = useActiveManufacturer();
  const activePlant = useActivePlant();
  const getMaterial = useGetMaterial();

  const [data, setData] = useState<SupplierMaterial | null>(null);

  useEffect(() => {
    if (!id) return;

    getMaterial({
      manufacturerId: activeManufacturer.id,
      plantId: activePlant.id,
      materialId: id,
    }).then(setData);
  }, [activeManufacturer, activePlant, getMaterial, id]);

  return data;
};

export const useMaterials = () => {
  const { activeManufacturer } = useActiveManufacturer();
  const activePlant = useActivePlant();

  const getMaterials = useGetPlantMaterials();

  const { data } = useSuspenseQuery({
    queryKey: [activeManufacturer.id, activePlant.id, "materials"],
    queryFn: () => {
      return Promise.all([
        getMaterials({
          manufacturerId: activeManufacturer.id,
          plantId: activePlant.id || "",
          type: "raw_materials",
        }),
        getMaterials({
          manufacturerId: activeManufacturer.id,
          plantId: activePlant.id || "",
          type: "packaging",
        }),
      ]);
    },
    select: ([rawMaterials, packagingMaterials]) => {
      return { rawMaterials, packagingMaterials };
    },
  });

  return data;
};

export const useAddMaterial = () => {
  const queryClient = useQueryClient();
  const { activeManufacturer } = useActiveManufacturer();
  const activePlant = useActivePlant();
  const postMaterials = usePostMaterials();

  return useMutation({
    mutationFn: ({ material }: { material: Parameters<typeof postMaterials>[0]["material"] }) =>
      postMaterials({
        manufacturerId: activeManufacturer.id,
        plantId: activePlant.id,
        material,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [activeManufacturer.id, activePlant.id, "materials"],
        exact: true,
      });
    },
  });
};

export const useUpdateMaterial = () => {
  const queryClient = useQueryClient();
  const { activeManufacturer } = useActiveManufacturer();
  const activePlant = useActivePlant();
  const putMaterial = usePutMaterial();

  return useMutation({
    mutationFn: ({ material }: { material: Parameters<typeof putMaterial>[0]["material"] }) =>
      putMaterial({
        manufacturerId: activeManufacturer.id,
        plantId: activePlant.id,
        material,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [activeManufacturer.id, activePlant.id, "materials"],
        exact: true,
      });
    },
  });
};

export const usePrechainProducts = () => {
  const { rawMaterials } = useMaterials();

  return useMemo(
    () => rawMaterials.filter((x) => x.prechain_product).map((x) => x.prechain_product),
    [rawMaterials],
  );
};
