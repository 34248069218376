import { UploadFileOutlined } from "@mui/icons-material";
import { CircularProgress, Radio } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Product } from "../../api/types";
import { Button } from "../../components/Button";
import { Text14, Text16 } from "../../components/Typography";
import { HARD_CODED_PROGRAM_OPERATION_IMAGES } from "../../lib/programOperator";
import { useProductCategories } from "../../state/manufacturers";

export const SelectProgramOperatorForm = ({
  editable,
  product,
  loading,
  onSubmit,
}: {
  editable: boolean;
  product: Product;
  loading?: boolean;
  onSubmit: (operatorId: string) => void;
}) => {
  const { t } = useTranslation();
  const [activeOperatorId, setActiveOperatorId] = useState<string | null>(null);

  const { productCategoriesMap } = useProductCategories();

  const programOperatorAvailability = productCategoriesMap[product.category.id]?.po_availability;

  return (
    <>
      <div className="flex flex-col divide-y divide-neutral-300 border-y border-neutral-300">
        {(programOperatorAvailability || []).map((option) => (
          <div
            className="grid grid-cols-[auto_1fr_auto] items-center gap-4 cursor-pointer py-4"
            key={option.po.id}
            onClick={(e) => {
              e.stopPropagation();
              if (loading) return;
              setActiveOperatorId(option.available ? option.po.id : null);
            }}
          >
            <div className="flex items-center justify-center overflow-hidden h-12 w-12 border">
              <img
                className={`w-full h-full object-contain ${!option.available && "opacity-30"}`}
                src={HARD_CODED_PROGRAM_OPERATION_IMAGES[option.po.id]}
              />
            </div>
            <div>
              <Text16 className={`font-bold ${!option.available ? "opacity-30" : ""}`}>
                {option.po.name}
              </Text16>
              {option.available && (
                <Text14>
                  {t("Verification time")}: {option.verification_time}
                </Text14>
              )}
            </div>
            <Radio
              disabled={loading || !option.available}
              checked={activeOperatorId === option.po.id}
              onChange={() => setActiveOperatorId(option.po.id)}
            />
          </div>
        ))}
      </div>
      <Button
        isDisabled={loading || !activeOperatorId}
        onPress={() => {
          if (!activeOperatorId) return;
          onSubmit(activeOperatorId);
        }}
      >
        {loading ? <CircularProgress size="24px" /> : <UploadFileOutlined />}
        {editable ? t("Generate Editable EPD") : t("Generate EPD")}
      </Button>
    </>
  );
};
