import { useSuspenseQuery } from "@tanstack/react-query";
import { useGetMe } from "../api/endpoints/manufacturers";
import { useGetProductCategories } from "../api/endpoints/product-categories";

export const useActiveManufacturer = () => {
  const getMe = useGetMe();

  const { data: manufacturer, refetch } = useSuspenseQuery({
    queryKey: ["getMe"],
    queryFn: getMe,
    retry: false,
  });

  return {
    activeManufacturer: manufacturer,
    refetch,
  };
};

export const useProductCategories = () => {
  const getProductCategories = useGetProductCategories();

  const { data } = useSuspenseQuery({
    queryKey: ["productCategories"],
    queryFn: getProductCategories,
    select: (productCategories) => {
      return {
        productCategories,
        productCategoriesMap: Object.fromEntries(
          productCategories.map((category) => [category.id, category]),
        ),
      };
    },
  });

  return data;
};
