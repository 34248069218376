import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Outlet } from "react-router-dom";

import { ErrorBoundary } from "@sentry/react";
import { QueryErrorResetBoundary } from "@tanstack/react-query";
import { Suspense } from "react";
import { Footer } from "../components/Footer";
import { Navbar } from "../components/Navbar";
import { SplashSuccess } from "../components/SplashSuccess";
import { Banner } from "../page-components/Banner";
import { VersionInfo } from "../page-components/VersionInfo";
import { ActivePlantProvider } from "../state/plants";
import { ErrorPage } from "./special/ErrorPage";

export const Index = () => {
  return (
    <>
      <Banner />
      <SplashSuccess />
      <QueryErrorResetBoundary>
        {({ reset }) => (
          <ErrorBoundary fallback={ErrorPage} onReset={reset}>
            <Suspense>
              <ActivePlantProvider>
                <div className="w-full flex-grow grid grid-cols-[auto,1fr] overflow-hidden">
                  <VersionInfo />
                  <Navbar />
                  <div className="flex-grow px-6 overflow-y-auto bg-white flex flex-col">
                    <Outlet />
                    <Footer />
                  </div>
                </div>
              </ActivePlantProvider>
            </Suspense>
          </ErrorBoundary>
        )}
      </QueryErrorResetBoundary>
      <ToastContainer position="bottom-right" draggable pauseOnHover closeButton hideProgressBar />
    </>
  );
};
