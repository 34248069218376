import {
  AddOutlined,
  Commit,
  Inventory2Outlined,
  TokenOutlined,
  UploadOutlined,
} from "@mui/icons-material";
import HomeWorkOutlinedIcon from "@mui/icons-material/HomeWorkOutlined";
import CircularProgress from "@mui/material/CircularProgress";
import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useMemo, useState } from "react";
import { DialogTrigger, FileTrigger } from "react-aria-components";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useBulkUploadMaterials } from "../../../api/endpoints/materials";
import { PlantMaterial } from "../../../api/types";
import { Button } from "../../../components/Button";
import { DataGrid } from "../../../components/DataGrid";
import { Link } from "../../../components/Link";
import { PageContainer } from "../../../components/PageContainer";
import { Popover } from "../../../components/Popover";
import { StatusIndicator } from "../../../components/StatusIndicator";
import { TopBar } from "../../../components/TopBar";
import { Label, Label16 } from "../../../components/Typography";
import { useElementaries } from "../../../state/elementaries";
import { useActiveManufacturer } from "../../../state/manufacturers";
import { useMaterials } from "../../../state/materials";
import { useActivePlant } from "../../../state/plants";
import { useSuppliers } from "../../../state/suppliers";
import { showSuccessToast } from "../../../util/toasts";

const useMaterialBulkUpload = () => {
  const queryClient = useQueryClient();
  const { activeManufacturer } = useActiveManufacturer();
  const activePlant = useActivePlant();
  const bulkUploadMaterials = useBulkUploadMaterials();
  const [loading, setLoading] = useState(false);

  const onChange: (files: FileList | null) => void = useCallback(
    async (files) => {
      if (!activePlant.id) {
        return;
      }

      const file = files?.[0];
      if (file && activePlant.id) {
        setLoading(true);

        try {
          const result = await bulkUploadMaterials({
            manufacturerId: activeManufacturer.id,
            plantId: activePlant.id,
            file,
          });

          queryClient.invalidateQueries({
            queryKey: [activeManufacturer.id, activePlant.id, "materials"],
            exact: true,
          });

          queryClient.invalidateQueries({
            queryKey: [activeManufacturer.id, activePlant.id, "suppliers"],
            exact: true,
          });
          showSuccessToast(
            `Materials uploaded successfully. Imported: ${result.imported}, Updated: ${result.updated}, Skipped: ${result.skipped}, Failed: ${result.failed} \n\n${result.errors.join("\n")}`,
          );
        } finally {
          setLoading(false);
        }
      }
    },
    [activeManufacturer.id, activePlant.id, bulkUploadMaterials, queryClient],
  );

  return { loading, onChange };
};

export const MaterialsList = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { rawMaterials, packagingMaterials } = useMaterials();

  const { data: suppliers } = useSuppliers();

  const { loading: uploadLoading, onChange } = useMaterialBulkUpload();

  const input = useMemo(
    () => (
      <>
        <FileTrigger onSelect={onChange}>
          <Button intent="secondary">
            {uploadLoading ? <CircularProgress size="24px" /> : <UploadOutlined />}
            {t("Upload Materials")}
          </Button>
        </FileTrigger>
        <DialogTrigger>
          <Button>
            <AddOutlined />
            {t("New Material")}
          </Button>
          <Popover>
            <div className="flex flex-col gap-2">
              <Link
                intent="tertiary"
                onPress={() => navigate("suppliers-and-materials/new-raw-material")}
              >
                <AddOutlined />
                {t("Add Material")}
              </Link>
              <Link
                intent="tertiary"
                onPress={() => navigate("suppliers-and-materials/new-packaging")}
              >
                <AddOutlined />
                {t("Add Packaging Material")}
              </Link>
              <Link
                intent="tertiary"
                onPress={() => navigate("suppliers-and-materials/new-prechain-product")}
              >
                <AddOutlined />
                {t("Add Own Product")}
              </Link>
            </div>
          </Popover>
        </DialogTrigger>
      </>
    ),
    [navigate, onChange, t, uploadLoading],
  );

  return (
    <PageContainer>
      <TopBar
        title={t("Materials")}
        subtitle={
          <>
            {rawMaterials.length} {t("materials", { count: rawMaterials.length })},{" "}
            {suppliers.length} {t("suppliers", { count: suppliers.length })}
          </>
        }
        icon={<TokenOutlined />}
        input={[...rawMaterials, ...packagingMaterials].length > 0 ? input : undefined}
      />
      <div className="flex-grow flex flex-col gap-4 py-8">
        {[...rawMaterials, ...packagingMaterials].length === 0 && (
          <div className="flex-grow w-full flex flex-col items-center justify-center gap-3">
            <div className="flex flex-col items-center gap-3">
              <Label className="flex items-center gap-2">{t("No materials added yet")}</Label>
              <div className="flex items-center gap-5">{input}</div>
            </div>
          </div>
        )}
        {rawMaterials.length > 0 && (
          <MaterialDataGrid inputs={rawMaterials} header={t("Material")} type="rawMaterials" />
        )}
        {packagingMaterials.length > 0 && (
          <MaterialDataGrid inputs={packagingMaterials} header={t("Packaging")} type="packaging" />
        )}
      </div>
    </PageContainer>
  );
};

const MaterialDataGrid = ({
  inputs: materials,
  header,
  type,
}: {
  inputs: PlantMaterial[];
  header: string;
  type: "rawMaterials" | "packaging";
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { elementaries } = useElementaries();

  const getRow = (input: PlantMaterial) => {
    if (input.supplier_product) {
      const item = input.supplier_product;
      return {
        id: item.id,
        category: elementaries.find((c) => c.id === item.elementary_id)!.name_en,
        productName: item.name,
        supplierCompany: item.supplier_plant?.supplier_company.name,
        plant: item.supplier_plant?.name,
        epdLinked: !!item.epd_id,
        isPrechainProduct: false,
      };
    }

    if (!input.prechain_product) {
      throw new Error("Invalid input");
    }

    const item = input.prechain_product;
    return {
      id: item.id,
      category: elementaries.find((c) => c.id === item.product.elementary_id)!.name_en,
      productName: item.product.name,
      supplierCompany: item.product.manufacturer.name,
      plant: item.plant_name,
      epdLinked: false,
      isPrechainProduct: true,
    };
  };

  const columns: GridColDef[] = useMemo(() => {
    return [
      {
        field: "category",
        headerName: header,
        width: 300,
        renderCell: ({ value }) => (
          <div className="flex items-center gap-2 h-full">
            {type === "rawMaterials" && <TokenOutlined />}
            {type === "packaging" && <Inventory2Outlined />}
            <Label16>{value}</Label16>
          </div>
        ),
      },
      {
        field: "productName",
        headerName: t("Name"),
        width: 300,
      },
      {
        field: "supplierCompany",
        headerName: t("Supplier"),
        width: 250,
        renderCell: ({ value, row }) => (
          <div className="flex items-center gap-3">
            {row.isPrechainProduct && <HomeWorkOutlinedIcon />}
            {value}
          </div>
        ),
      },
      {
        field: "plant",
        headerName: t("Supplier Plant"),
        width: 200,
        renderCell: ({ value, row }) => (
          <div className="flex items-center gap-3">
            {row.isPrechainProduct && <HomeWorkOutlinedIcon />}
            {value}
          </div>
        ),
      },
      {
        field: "epdLinked",
        headerName: t("EPD linked"),
        width: 200,
        align: "center",
        // use a custom component for this one
        renderCell: ({ value, row }) => (
          <div className="h-full flex items-center">
            {row.isPrechainProduct ? (
              <div className="flex items-center gap-1">
                <Commit fontSize="small" className="-translate-x-0.5" />
                <Label16>{t("Own Product")}</Label16>
              </div>
            ) : (
              <StatusIndicator
                text={value ? "EPD" : t("Secondary Data")}
                done={!!value}
                missing={!value}
              />
            )}
          </div>
        ),
      },
    ];
  }, [t, header, type]);

  return (
    <>
      <div className="max-h-[80vh] flex flex-col gap-1">
        <DataGrid
          rows={materials
            .map(getRow)
            .sort(
              (a, b) =>
                a.category.localeCompare(b.category) || a.productName.localeCompare(b.productName),
            )}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 50,
              },
            },
          }}
          pageSizeOptions={[10]}
          onRowClick={(params: GridRowParams<{ id: string; isPrechainProduct: boolean }>) => {
            if (params.row.isPrechainProduct) {
              navigate(`/suppliers-and-materials/edit-prechain-product/${params.row.id}`);
            } else {
              navigate(`/suppliers-and-materials/edit/${params.row.id}`);
            }
          }}
        />
      </div>
    </>
  );
};
