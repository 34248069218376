import { ResponsiveRadar } from "@nivo/radar";
import { EndOfScale, Impact } from "../../api/types";
import { Text14 } from "../../components/Typography";
import { formatPrecision } from "../../util/format";

import colors from "tailwindcss/colors";
const yellow300 = colors.yellow[300];

export const RadarChart = ({
  impacts,
  endsOfScale,
}: {
  impacts: Impact[];
  endsOfScale: EndOfScale[];
}) => {
  const normalizedImpacts = impacts.map((impact) => {
    const maxValue = endsOfScale.find(
      ({ indicator_name }) => impact.indicator_name === indicator_name,
    )?.value;

    return {
      ...impact,
      valueNormalized: maxValue ? Math.min(impact.value / maxValue, 1) : 0,
    };
  });

  return (
    <ResponsiveRadar
      data={normalizedImpacts}
      keys={["valueNormalized"]}
      indexBy="indicator_name"
      gridShape="linear"
      fillOpacity={0.7}
      dotBorderWidth={0.5}
      dotBorderColor="#404D3E"
      dotColor={yellow300}
      valueFormat=">-.3e"
      maxValue={1}
      sliceTooltip={({ index }) => {
        const impact = impacts.find((i) => i.indicator_name === index);

        return (
          <div className="bg-white p-2 rounded-md shadow-md">
            <Text14>
              {index}{" "}
              <strong>
                {impact?.value && formatPrecision(impact.value, 4)} {impact?.unit}
              </strong>
            </Text14>
          </div>
        );
      }}
      margin={{ top: 40, right: 80, bottom: 40, left: 80 }}
      borderColor={{ from: "color" }}
      gridLabelOffset={20}
      dotSize={4}
      colors={[yellow300]}
    />
  );
};
