import type { ReactNode } from "react";
import { createContext, useContext, useMemo, useState } from "react";
import { matchPath, useLocation } from "react-router-dom";
import { useAllPages } from "../config/useNavbarItems";

const initialState = {
  collapsed: false,
  setCollapsed: () => {},
  showNavigation: true,
};

const Context = createContext<ReturnType<typeof useContextValue>>(initialState);

const useShowNavigation = () => {
  const [showNavigation, setShowNavigation] = useState<boolean>();

  const activePath = useLocation().pathname;
  const allPages = useAllPages();

  const activeItem = useMemo(() => {
    return Object.values(allPages).find((item) => matchPath(item.path, activePath));
  }, [activePath, allPages]);

  if (activeItem?.hideNavbar && showNavigation) {
    setShowNavigation(false);
  } else if (!activeItem?.hideNavbar && !showNavigation) {
    setShowNavigation(true);
  }

  return { showNavigation };
};

const useContextValue = () => {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const { showNavigation } = useShowNavigation();

  return { showNavigation, collapsed, setCollapsed };
};

export const NavigationProvider = ({ children }: { children: ReactNode }) => (
  <Context.Provider value={useContextValue()}>{children}</Context.Provider>
);

export const useNavigation = () => {
  return useContext(Context);
};
