import { CheckCircle, RadioButtonUncheckedOutlined } from "@mui/icons-material";
import { useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Label12, Label14 } from "../../components/Typography";
import { useProductCategories } from "../../state/manufacturers";

// This is essentially just a custom ComboBoxMultiFieldConnected
//
// <ComboBoxMultiFieldConnected
//   name="product_categories"
//   options={options}
//   label={t("what do you produce in this plant?")}
//   wrapValueAt={5}
//   isrequired
// />

export const CustomProductCategoryPicker = () => {
  const { productCategories } = useProductCategories();
  const { watch, setValue, setError, clearErrors, formState } = useFormContext();
  const { t } = useTranslation();
  const selectedCategories: string[] = watch("product_categories");
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (selectedCategories.length === 0) {
      setError("product_categories", { type: "custom", message: t("Required") });
      setHasError(true);
    } else {
      clearErrors("product_categories");
      setHasError(false);
    }
  }, [selectedCategories, setError, clearErrors, t]);

  const toggleCategory = (categoryId: string) => {
    const isSelected = selectedCategories.includes(categoryId);

    if (isSelected) {
      setValue(
        "product_categories",
        selectedCategories.filter((id) => id !== categoryId),
      );
    } else {
      setValue("product_categories", [...selectedCategories, categoryId]);
    }
  };

  const groupedCategories = useMemo(() => {
    return Object.groupBy(productCategories, (cat) => cat.group.en);
  }, [productCategories]);

  return (
    <div className="space-y-6">
      <div>
        <Label12 className="text-neutral-400">{t("What do you produce in this plant?")}</Label12>
        <hr className="border-neutral-200 mt-4" />
        {hasError && formState.submitCount > 0 && (
          <span className="text-red-500 text-sm font-bold">
            {t("At least one product category required")}
          </span>
        )}
      </div>
      <div
        className="grid gap-6"
        style={{
          gridTemplateColumns: `repeat(${Object.entries(groupedCategories).length}, 1fr)`,
        }}
      >
        {Object.entries(groupedCategories).map(([category, items]) => (
          <div key={category} className="space-y-4">
            <Label12 className="text-builtgreen">{category}</Label12>
            <ul className="list-none space-y-4">
              {items!.map((category) => {
                const selected = selectedCategories.includes(category.id);
                // TODO: might be required soon
                const disabled = false;

                return (
                  <li
                    key={category.id}
                    onClick={() => {
                      toggleCategory(category.id);
                    }}
                    className="py-1 flex items-center gap-2 cursor-pointer"
                  >
                    {selected && <CheckCircle className="text-steelblue" />}
                    {!selected && <RadioButtonUncheckedOutlined className="text-builtgreen" />}
                    <Label14 className={disabled ? "text-neutral-400" : ""}>
                      {category.name.en}
                      {disabled ? ` (${t("coming soon")})` : ""}
                    </Label14>
                  </li>
                );
              })}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};
