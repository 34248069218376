import { memo, Suspense, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useGetEndsOfScale } from "../api/endpoints/product-categories";
import { EndOfScale, Product } from "../api/types";
import { ConfirmationModal } from "../components/ConfirmationModal";
import { PageContainer } from "../components/PageContainer";
import { Text16 } from "../components/Typography";
import { ProductDetailPageAnalyse } from "../page-components/product-detail/ProductDetailPageAnalyse";
import { ProductDetailPagePassport } from "../page-components/product-detail/ProductDetailPagePassport";
import { ProductDetailSidePanel } from "../page-components/product-detail/ProductDetailSidePanel";
import { ProductDetailTopBar } from "../page-components/product-detail/ProductDetailTopBar";
import { useProductDeletion } from "../page-components/product-detail/useProductDeletion";
import { CATEGORIES_WITHOUT_RECIPE } from "../state/HARD_CODED";
import { useProduct } from "../state/products";
import { useGetLinkWithParams } from "../url/useGetLinkWithParams";

export const ProductDetailPage = () => {
  return (
    <Suspense>
      <ProductDetailPageContent />
    </Suspense>
  );
};

const ProductDetailPageContent = () => {
  const { id } = useParams();
  const { data: product } = useProduct(id);

  if (!product) return null;

  return <ProductDetail product={product} />;
};

const useCategoryEndsOfScale = (categoryId: string) => {
  const getEndsOfScale = useGetEndsOfScale();
  const [endsOfScale, setEndsOfScale] = useState<EndOfScale[]>();

  useEffect(() => {
    getEndsOfScale(categoryId).then((response) => {
      setEndsOfScale(response);
    });
  }, [categoryId, getEndsOfScale]);

  return endsOfScale || [];
};

const ProductDetail = memo(({ product }: { product: Product }) => {
  const endsOfScale = useCategoryEndsOfScale(product.category.id);

  const { t } = useTranslation();

  const navigate = useNavigate();

  const getLinkWithParams = useGetLinkWithParams();
  const onEdit = () =>
    navigate(
      getLinkWithParams(
        !!product.production_process_id && !CATEGORIES_WITHOUT_RECIPE.includes(product.category.id)
          ? "/edit/product-recipe"
          : "/edit/product-specs",
        {
          selectedProduct: product.id,
        },
      ),
    );

  const { deletionStaged, removeProduct, setDeletionStaged } = useProductDeletion({
    product,
  });

  const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);
  const [activeTab, setActiveTab] = useState<"passport" | "analyse">("passport");

  return (
    <>
      <PageContainer>
        <ProductDetailTopBar
          onEdit={onEdit}
          product={product}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          onDelete={() => setDeletionStaged(true)}
          onOpenSidePanel={() => setIsSidePanelOpen(true)}
        />
        <Suspense>
          <ProductDetailSidePanel
            product={product}
            open={isSidePanelOpen}
            setOpen={setIsSidePanelOpen}
          />
        </Suspense>
        {activeTab === "passport" && (
          <ProductDetailPagePassport product={product} endsOfScale={endsOfScale} />
        )}
        {activeTab === "analyse" && <ProductDetailPageAnalyse product={product} />}
        {deletionStaged && (
          <ConfirmationModal
            title={t("Delete a product including its declarations")}
            content={
              <>
                <Text16 className="font-bold">{product.name}</Text16>
                <Text16>
                  {t("Are you sure you want to delete this product and all of its declarations?")}
                </Text16>
              </>
            }
            danger
            onConfirm={removeProduct}
            onCancel={() => setDeletionStaged(false)}
          />
        )}
      </PageContainer>
    </>
  );
});
