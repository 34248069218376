import { DataGrid as DataGridPrimitive } from "@mui/x-data-grid";
import { ComponentProps } from "react";

export const DataGrid = (props: ComponentProps<typeof DataGridPrimitive>) => {
  return (
    <DataGridPrimitive
      {...props}
      className="h-full bg-white"
      rowHeight={48}
      columnHeaderHeight={64}
      sx={{
        fontSize: 16,
        borderRadius: 4,
        overflow: "hidden",
        fontWeight: "normal",
        color: "var(--builtgreen)",
        fontFamily: "CircularStd",
        "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-columnHeader:focus-within": {
          outline: "none",
        },
        "& .MuiDataGrid-columnHeader, .MuiDataGrid-filler": {
          backgroundColor: "#F5F5F5",
          textTransform: "uppercase",
          fontSize: 14,
          color: "#737373",
          fontWeight: "bold",
          fontFamily: "CircularStd",
          padding: "0 10px 0 24px",
        },
        "& .MuiDataGrid-cell": {
          padding: "0 10px 0 24px",
        },
        "& .MuiDataGrid-row:hover": {
          cursor: "pointer",
        },
      }}
    />
  );
};
