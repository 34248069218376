import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Product } from "../../api/types";
import { useRemoveProduct } from "../../state/products";
import { showSuccessToast } from "../../util/toasts";

export const useProductDeletion = ({ product }: { product: Product }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [deletionStaged, setDeletionStaged] = useState(false);
  const { mutate } = useRemoveProduct();

  const removeProduct = useCallback(
    () =>
      mutate(
        {
          manufacturerId: product.manufacturer_id,
          plantId: product.plant_id,
          productId: product.id,
        },
        {
          onSuccess: () => {
            setDeletionStaged(false);
            showSuccessToast(
              t(`Successfully deleted product '{{ name }}'`, { name: product.name }),
            );
            navigate("/products");
          },
        },
      ),
    [mutate, navigate, product.id, product.manufacturer_id, product.name, product.plant_id, t],
  );

  return { deletionStaged, setDeletionStaged, removeProduct };
};
