import { Button as ButtonPrimitive } from "react-aria-components";
import { useTranslation } from "react-i18next";
import tw from "tailwind-styled-components";
import { Impact, SourceImpacts } from "../../api/types";
import { Tooltip, TooltipTrigger } from "../../components/Tooltip";
import { Label12, Label14 } from "../../components/Typography";
import { coreIndicators } from "../../lca/impact";
import { useImpactColors } from "./useImpactColors";

const Cell = tw.div`
  border-r border-b border-neutral-300 px-2 py-4 line-clamp-1 h-full flex items-center relative
`;

export type Totals = Record<Impact["indicator_name"], number>;

export const BreakdownPerMaterialChart = ({
  impactsPerSource,
  totals,
}: {
  impactsPerSource: SourceImpacts[];
  totals: Totals;
}) => {
  const { t } = useTranslation();

  const colors = useImpactColors();

  const getGwpTotal = (sourceImpacts: SourceImpacts) =>
    sourceImpacts.impacts_total.find((x) => x.indicator_name === "GWP-total")?.value ?? 0;

  return (
    <div className="grid grid-cols-[300px_repeat(8,1fr)] border-b border-t border-neutral-300 items-center pb-10">
      <Cell>{t("Source")}</Cell>
      {Array.from(coreIndicators).map((indicator) => (
        <Cell key={indicator} className="flex items-center gap-2">
          <div
            className="w-2 h-2 rounded-full flex-shrink-0"
            style={{ backgroundColor: colors[indicator] }}
          />
          <Label12 className="line-clamp-1" title={indicator}>
            {indicator}
          </Label12>
        </Cell>
      ))}
      {impactsPerSource
        .sort((a, b) => getGwpTotal(b) - getGwpTotal(a))
        .map((impactPerSource) => {
          const data = Array.from(coreIndicators).map((indicator) => {
            const totalImpact = impactPerSource.impacts_total.find(
              (x) => x.indicator_name === indicator,
            );

            const totalValue = totalImpact?.value ?? 0;
            const unit = totalImpact?.unit ?? "";

            const transportValue =
              impactPerSource.impacts_transport?.find((x) => x.indicator_name === indicator)
                ?.value ?? 0;

            return {
              indicatorName: indicator,
              transportApplies: !!impactPerSource.impacts_transport,
              total: totalValue,
              totalShare: (totalValue / totals[indicator]) * 100,
              transportShare: (transportValue / totals[indicator]) * 100,
              elementShare: ((totalValue - transportValue) / totals[indicator]) * 100,
              unit,
            };
          });

          return (
            <>
              <Cell key={impactPerSource.name + "header"}>
                <Label14 title={impactPerSource.name} className="line-clamp-1">
                  {impactPerSource.name}
                </Label14>
              </Cell>
              {data.map((cell, index) => {
                return (
                  <Cell
                    className="flex justify-start items-center gap-1"
                    key={impactPerSource.name + index}
                  >
                    <TooltipTrigger>
                      <ButtonPrimitive
                        className="h-full flex items-center"
                        style={{
                          width: `${Math.abs(cell.totalShare)}%`,
                        }}
                      >
                        <div
                          className="h-full"
                          style={{
                            width: `${(cell.elementShare / cell.totalShare) * 100}%`,
                            backgroundColor: colors[cell.indicatorName],
                          }}
                        />
                        <div
                          className="h-full opacity-50"
                          style={{
                            width: `${(cell.transportShare / cell.totalShare) * 100}%`,
                            backgroundColor: colors[cell.indicatorName],
                          }}
                        />
                      </ButtonPrimitive>
                      <Tooltip>
                        <strong>
                          {cell.indicatorName}: {cell.totalShare.toFixed(1)}% (
                          {cell.total.toFixed(2)} {cell.unit})
                        </strong>
                        {cell.transportApplies && (
                          <ul className="list-disc pl-4">
                            <li>
                              {t("Element share")}: {cell.elementShare.toFixed(1)}%
                            </li>
                            <li>
                              {t("Transport share")}: {cell.transportShare.toFixed(1)}%
                            </li>
                          </ul>
                        )}
                      </Tooltip>
                    </TooltipTrigger>
                    <Label12 className="text-right">{cell.totalShare.toFixed(1)}%</Label12>
                  </Cell>
                );
              })}
            </>
          );
        })}
    </div>
  );
};
