import { Suspense, useEffect, useMemo } from "react";
import { Link } from "react-aria-components";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import tw from "tailwind-styled-components";
import { Loading } from "../components/Loading";
import { PageContainer } from "../components/PageContainer";
import { ProgressIndicatorIcon } from "../components/ProgressIndicatorIcon";
import { StatusIndicator } from "../components/StatusIndicator";
import { TopBar } from "../components/TopBar";
import { Heading4, Label14 } from "../components/Typography";
import { useActiveOnboardingStep } from "../state/onboarding";

const Card = tw(Link)<{ $disabled?: boolean; $active?: boolean; $done?: boolean }>`
  h-56 rounded-2xl border p-6 flex flex-col justify-between shadow-e-md
  ${({ $disabled }) => $disabled && "pointer-events-none shadow-none [&_p]:text-neutral-300 [&_h4]:text-neutral-300  "}
  ${({ $active }) => $active && "border-energyyellow"}
  ${({ $done }) => $done && "bg-energyyellow"}
`;

const CardGrid = tw("div")<{ $plantNeedsNoMaterials?: boolean }>`
  w-full
  py-8
  grid items-center gap-6
  ${({ $plantNeedsNoMaterials }) =>
    $plantNeedsNoMaterials ? "grid-cols-2 max-w-2xl" : "grid-cols-3 max-w-4xl"}
`;

type OnboardingStep = {
  description: string;
  descriptionDone: string;
  goToHref: string;
};

const OnboardingSteps = () => {
  const { t } = useTranslation();
  const { step: activeStep, plantNeedsNoMaterials } = useActiveOnboardingStep();

  const steps: OnboardingStep[] = useMemo(
    () => [
      ...(plantNeedsNoMaterials
        ? []
        : [
            {
              description: t("Set up your first materials"),
              descriptionDone: t("First materials are set up"),
              goToHref: "/suppliers-and-materials",
            },
          ]),
      {
        description: t("Set up your first production process"),
        descriptionDone: t("Production is set up"),
        goToHref: "/production",
      },
      {
        description: t("Add your first product"),
        descriptionDone: t("First product is added"),
        goToHref: "/edit/product-details",
      },
    ],
    [t, plantNeedsNoMaterials],
  );

  const navigate = useNavigate();
  const onboardingDone = activeStep > 3;
  useEffect(() => {
    if (onboardingDone) navigate("/");
  }, [onboardingDone, navigate]);

  const maxSteps = plantNeedsNoMaterials ? 2 : 3;

  return (
    <div className="flex-grow flex flex-col items-center justify-center gap-3 pb-20">
      <div className="flex flex-col items-center">
        <Label14 className="text-neutral-400">
          {t("Data setup")} {Math.min(activeStep - (plantNeedsNoMaterials ? 1 : 0), maxSteps)} /{" "}
          {maxSteps}
        </Label14>
        <Heading4>{t("Let's get you started")}</Heading4>
      </div>
      <CardGrid $plantNeedsNoMaterials={plantNeedsNoMaterials}>
        {steps.map((step, index) => {
          const currentStep = index + 1 + (plantNeedsNoMaterials ? 1 : 0);

          const done = currentStep < activeStep;
          const active = currentStep === activeStep;
          const disabled = currentStep > activeStep;

          return (
            <Card
              key={index}
              href={step.goToHref}
              $active={active}
              $done={done}
              $disabled={disabled}
            >
              <StatusIndicator
                small
                missing={disabled || active}
                text={
                  done ? t("Done") : `${t("Step")} ${currentStep - (plantNeedsNoMaterials ? 1 : 0)}`
                }
                ready={done}
              />
              <Heading4>{done ? step.descriptionDone : step.description}</Heading4>
            </Card>
          );
        })}
      </CardGrid>
    </div>
  );
};

const OnboardingIcon = () => {
  const { step: activeStep } = useActiveOnboardingStep();

  return <ProgressIndicatorIcon done={activeStep / 4} />;
};

export const Onboarding = () => {
  const { t } = useTranslation();

  return (
    <PageContainer>
      <TopBar
        icon={
          <Suspense>
            <OnboardingIcon />
          </Suspense>
        }
        title={t("Data setup")}
      />
      <Suspense fallback={<Loading />}>
        <OnboardingSteps />
      </Suspense>
    </PageContainer>
  );
};
