import { useMutation, useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import {
  useGetDeclaration,
  useGetReasons,
  usePostDeclarations,
} from "../api/endpoints/declarations";
import { Product } from "../api/types";

export const useDeclaration = ({
  productId,
  plantId,
  manufacturerId,
}: {
  productId: string;
  plantId: string;
  manufacturerId: string;
}) => {
  const getDeclarations = useGetDeclaration();

  return useSuspenseQuery({
    queryFn: () => getDeclarations({ productId, plantId, manufacturerId }),
    queryKey: [manufacturerId, plantId, productId, "declarations"],
  });
};

export const useAddDeclaration = ({ product }: { product: Product }) => {
  const queryClient = useQueryClient();
  const postDeclarations = usePostDeclarations();
  const getReasons = useGetReasons();

  return useMutation({
    mutationFn: async (operatorId: string) => {
      const reasons = await getReasons({
        manufacturerId: product.manufacturer_id,
        plantId: product.plant_id,
        productId: product.id,
      });

      return postDeclarations({
        manufacturerId: product.manufacturer_id,
        plantId: product.plant_id,
        productId: product.id,
        reasonId: reasons[0].id,
        programOperatorId: operatorId,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [product.manufacturer_id, product.plant_id, product.id, "declarations"],
      });
    },
  });
};
