import { useProductionProcesses } from "../api/endpoints/production-processes";
import { Plant, ProductionProcess } from "../api/types";
import { exists } from "../util/commonUtil";
import { CATEGORIES_WITHOUT_RECIPE } from "./HARD_CODED";
import { useMaterials } from "./materials";
import { useActivePlant } from "./plants";
import { useActivePlantProducts } from "./products";

const processHasIOFilled = (process: ProductionProcess) => {
  return process.input_output_per_year?.some(
    (ioPerYear) =>
      ioPerYear.production_output > 0 &&
      Object.values(ioPerYear.input_output).every((io) => exists(io)),
  );
};

const getPlantNeedsNoMaterials = (plant: Plant) => {
  return plant.product_categories.every((id) => CATEGORIES_WITHOUT_RECIPE.includes(id));
};

export const useActiveOnboardingStep = () => {
  const activePlant = useActivePlant();
  const { data: productionProcesses } = useProductionProcesses({ plantId: activePlant.id });
  const { rawMaterials } = useMaterials();
  const { data: products } = useActivePlantProducts();
  const plantNeedsNoMaterials = getPlantNeedsNoMaterials(activePlant);

  const returnWithStep = (step: number) => ({ step, plantNeedsNoMaterials });

  if (rawMaterials.length === 0 && !plantNeedsNoMaterials) return returnWithStep(1);

  if (productionProcesses.length === 0 || !productionProcesses.some(processHasIOFilled))
    return returnWithStep(2);

  if (products.length === 0) {
    return returnWithStep(3);
  }

  return returnWithStep(4);
};
