import { useSuspenseQuery } from "@tanstack/react-query";
import { useGetElementaries } from "../api/endpoints/elementaries";

const useElementariesQuery = () => {
  const getElementaries = useGetElementaries();

  return useSuspenseQuery({
    queryKey: ["elementaries"],
    queryFn: getElementaries,
    select: (elementaries) => {
      return {
        elementaries,
        elementariesMap: Object.fromEntries(elementaries.map((item) => [item.id, item])),
      };
    },
  });
};

export const useElementaries = () => {
  const {
    data: { elementaries, elementariesMap },
  } = useElementariesQuery();

  return { elementaries, elementariesMap };
};
