import { LockOpenOutlined, LockOutlined } from "@mui/icons-material";
import { CircularProgress, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { usePatchPlant } from "../../api/endpoints/plants";
import { Alert } from "../../components/Alert";
import { Button } from "../../components/Button";
import { Card, CardDivider } from "../../components/Card";
import { NumberFieldConnected } from "../../form-components/NumberFieldConnected";
import { ProductionForm } from "../../pages/Production";
import { useActivePlant } from "../../state/plants";
import { exists } from "../../util/commonUtil";
import { showSuccessToast } from "../../util/toasts";
import { useWarnBeforeUnload } from "../../util/useWarnBeforeUnload";
import { ElectricityMixPreview } from "./ElectricityMixPreview";

export interface ElectricityMixForm {
  nuclear: number;
  solar: number;
  wind: number;
  hydropower: number;
  geothermal: number;
  bioenergy: number;
  gas: number;
  oil: number;
  coal: number;
}

const useLabels = (): Record<keyof ElectricityMixForm, "string"> => {
  const { t } = useTranslation();

  return useMemo(
    () => ({
      nuclear: t("Nuclear"),
      solar: t("Solar"),
      wind: t("Wind"),
      hydropower: t("Hydropower"),
      geothermal: t("Geothermal"),
      bioenergy: t("Bioenergy"),
      gas: t("Gas"),
      oil: t("Oil"),
      coal: t("Coal and peat"),
    }),
    [t],
  );
};

const useColors = (): Record<keyof ElectricityMixForm, string> => {
  return useMemo(
    () => ({
      solar: "#EFE457",
      wind: "#FFEFB8",
      nuclear: "#D9E8F5",
      hydropower: "#DEDCCD",
      geothermal: "#F3F2EE",
      bioenergy: "#CFE0DE",
      gas: "#A7C1D3",
      oil: "#D9D9D9",
      coal: "#F8F5EE",
    }),
    [],
  );
};

const defaultValues: ElectricityMixForm = {
  nuclear: 0,
  solar: 0,
  wind: 0,
  hydropower: 0,
  geothermal: 0,
  bioenergy: 0,
  gas: 0,
  oil: 0,
  coal: 0,
};

export const ElectricityMix = ({
  editing,
  setEditing,
}: {
  editing?: ProductionForm;
  setEditing: (value?: ProductionForm) => void;
}) => {
  const isEditMode = editing === ProductionForm.ElectricityMix;
  const isEditingOtherForm = editing && !isEditMode;

  const activePlant = useActivePlant();
  const { t } = useTranslation();

  const methods = useForm<ElectricityMixForm>({
    defaultValues: activePlant.electricity_mix || defaultValues,
  });

  useWarnBeforeUnload(isEditMode && methods.formState.isDirty);

  const [averageMix, setAverageMix] = useState(!activePlant.electricity_mix);

  const onChangeAverageMix = useCallback(
    (newValue: boolean) => {
      setAverageMix(newValue);

      if (!newValue && activePlant.electricity_mix) {
        methods.reset(activePlant.electricity_mix);
      }
    },
    [methods, activePlant],
  );

  useEffect(() => {
    if (!activePlant) return;

    setAverageMix(!activePlant.electricity_mix);
    methods.reset(activePlant.electricity_mix || defaultValues);
  }, [activePlant, methods]);

  const valueIsValid = (value?: unknown) => {
    // value is a string due to the TextField component
    // ideally: value should be a number right away in the form state
    return exists(value) && !isNaN(Number(value));
  };

  const values = methods.watch();

  const total = useMemo(() => {
    if (!values) return 0;

    return Object.values(values).reduce((sum, val) => sum + Number(val), 0);
  }, [values]);

  const error = useMemo(() => {
    if (!activePlant || averageMix) return;

    for (const key of Object.keys(values) as (keyof ElectricityMixForm)[]) {
      if (!valueIsValid(values[key])) {
        return t("Please enter a valid number for all fields");
      }
    }

    if (Math.abs(total - 100) > 0.01) {
      return t("The sum of all sources must be 100%") + `(${total}%)`;
    }
  }, [activePlant, averageMix, t, total, values]);

  const { mutate: patchPlant, isPending } = usePatchPlant();

  const onSubmit: SubmitHandler<ElectricityMixForm> = (fields) => {
    patchPlant(
      {
        plantId: activePlant.id,
        plant: {
          ...activePlant,
          electricity_mix: averageMix
            ? null
            : {
                ...fields,
              },
        },
      },
      {
        onSuccess: () => {
          showSuccessToast(t("Successfully saved electricity mix"));
        },
      },
    );
  };

  const labels = useLabels();
  const colors = useColors();

  if (!activePlant) {
    return null;
  }

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit((values) => {
          onSubmit(values);
          setEditing(undefined);
        })}
      >
        <Card
          title={t("Grid Electricity")}
          input={
            isEditMode ? (
              <>
                <Button
                  intent="tertiary"
                  size="small"
                  type="button"
                  onPress={() => {
                    methods.reset(activePlant.electricity_mix || defaultValues);
                    setAverageMix(!activePlant.electricity_mix);
                    setEditing(undefined);
                  }}
                >
                  {t("Cancel")}
                </Button>
                <Button intent="primary" size="small" type="submit" isDisabled={!!error}>
                  {t("Save data")}
                  {isPending ? <CircularProgress size="24px" /> : <LockOpenOutlined />}
                </Button>
              </>
            ) : (
              <Button
                intent="tertiary"
                size="small"
                type="button"
                isDisabled={isEditingOtherForm}
                onPress={() => setEditing(ProductionForm.ElectricityMix)}
              >
                {t("Edit data")}
                <LockOutlined />
              </Button>
            )
          }
        >
          <div className="grid grid-cols-[1.681fr_1fr] gap-10 pb-3">
            <div>
              <RadioGroup row>
                <FormControlLabel
                  checked={averageMix}
                  onChange={() => onChangeAverageMix(true)}
                  control={<Radio />}
                  label={`${t("Average Mix")} (${activePlant.country})`}
                  disabled={!isEditMode}
                />
                <FormControlLabel
                  checked={!averageMix}
                  onChange={() => onChangeAverageMix(false)}
                  control={<Radio />}
                  label={t("Custom Mix")}
                  disabled={!isEditMode}
                />
              </RadioGroup>
              <p className="font-medium">
                {averageMix
                  ? t(
                      "The plant uses the standard electricity mix from the country it's located in.",
                    )
                  : t(
                      "By selecting this option, I confirm that I have a Guarantee of Origin (GoO) certificate that proves the source of the electricity.",
                    )}
              </p>
            </div>
            <div className="justify-self-end">
              <Alert intent="info">
                {t("You can find your specific energy mix on your energy bill.")}
              </Alert>
            </div>
          </div>
          {!averageMix && (
            <>
              <CardDivider />
              <div className="grid grid-cols-[1.681fr_1fr] gap-12 mt-3 border-neutral-300">
                <ElectricityMixFields
                  averageMix={averageMix}
                  error={error}
                  isEditMode={isEditMode}
                />
                <ElectricityMixPreview values={values} labels={labels} colors={colors} />
              </div>
            </>
          )}
        </Card>
      </form>
    </FormProvider>
  );
};

const ElectricityMixFields = ({
  averageMix,
  error,
  isEditMode,
}: {
  averageMix: boolean;
  error?: string;
  isEditMode?: boolean;
}) => {
  const { t } = useTranslation();
  const sharedProps = useMemo(
    () => ({
      isRequired: true,
      minValue: 0,
      maxValue: 100,
      inputProps: { addonRight: "%" },
      isDisabled: averageMix || !isEditMode,
    }),
    [averageMix, isEditMode],
  );

  return (
    <div>
      <div className="grid grid-cols-3 gap-x-8 gap-y-16 mt-8">
        <NumberFieldConnected name="nuclear" label={t("Nuclear")} {...sharedProps} />
        <NumberFieldConnected name="solar" label={t("Solar")} {...sharedProps} />
        <NumberFieldConnected name="wind" label={t("Wind")} {...sharedProps} />
        <NumberFieldConnected name="hydropower" label={t("Hydropower")} {...sharedProps} />
        <NumberFieldConnected name="geothermal" label={t("Geothermal")} {...sharedProps} />
        <NumberFieldConnected name="bioenergy" label={t("Bioenergy")} {...sharedProps} />
        <NumberFieldConnected name="gas" label={t("Gas")} {...sharedProps} />
        <NumberFieldConnected name="oil" label={t("Oil")} {...sharedProps} />
        <NumberFieldConnected name="coal" label={t("Coal and peat")} {...sharedProps} />
      </div>
      <div className="flex gap-3 justify-end mt-6 items-center">
        {error && <p className="text-red-500 font-semibold">{error}</p>}
      </div>
    </div>
  );
};
