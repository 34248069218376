import { Add } from "@mui/icons-material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import tw from "tailwind-styled-components";
import { useProductionProcesses } from "../../api/endpoints/production-processes";
import { Button } from "../../components/Button";
import { ComboBoxField } from "../../components/ComboBoxField";
import { Label16 } from "../../components/Typography";
import { useActivePlant } from "../../state/plants";
import { usePeriodUnderReviewYearOptions } from "../../util/usePeriodUnderReviewYearOptions";
import { ProductionProcessList } from "./ProductionProcessList";

const StickyHeader = tw("div")`
  sticky
  top-20
  z-10
  bg-white
  p-4
  flex items-center justify-between
`;

export const ProductionProcesses = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const yearOptions = usePeriodUnderReviewYearOptions();
  const [activeYear, setActiveYear] = useState<number>(yearOptions[yearOptions.length - 1].id);

  const activePlant = useActivePlant();
  const { data: productionProcesses } = useProductionProcesses({ plantId: activePlant.id });

  return (
    <div className="relative">
      <StickyHeader>
        <Label16>{t("Production Processes")}</Label16>
        {productionProcesses.length > 0 && (
          <div className="flex items-center gap-6">
            <ComboBoxField
              value={yearOptions.find(({ id }) => id === activeYear)}
              label={t("Year")}
              options={yearOptions}
              onChange={(_, opt) => {
                if (!opt) return;
                setActiveYear(opt.id as number);
              }}
            />
            <Button intent="primary" onPress={() => navigate("/production/processes/new")}>
              {t("Add production process")}
              <Add />
            </Button>
          </div>
        )}
      </StickyHeader>
      <div className="relative z-[1] space-y-6">
        <ProductionProcessList activeYear={activeYear} productionProcesses={productionProcesses} />
      </div>
    </div>
  );
};
