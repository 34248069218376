import { useCallback } from "react";
import { BulkUploadResult, Product, RecipeItem } from "../types";
import { useApi } from "../useApi";

export const useGetProducts = () => {
  const api = useApi<Product[]>();

  return useCallback(
    ({ manufacturerId, plantId }: { manufacturerId: string; plantId: string }) =>
      api({ url: `/v1/manufacturers/${manufacturerId}/plants/${plantId}/products` }),
    [api],
  );
};
export const useGetProduct = () => {
  const api = useApi<Product>();

  return useCallback(
    ({ id, manufacturerId, plantId }: { id: string; manufacturerId: string; plantId: string }) =>
      api({ url: `/v1/manufacturers/${manufacturerId}/plants/${plantId}/products/${id}` }),
    [api],
  );
};

export const usePostProduct = () => {
  const api = useApi<Product>({ method: "POST" });

  return useCallback(
    ({
      manufacturerId,
      plantId,
      product,
    }: {
      manufacturerId: string;
      plantId: string;
      product: Omit<
        Product,
        | "id"
        | "plant_id"
        | "manufacturer_id"
        | "production_process_id"
        | "tech_specs_schema"
        | "mass"
        | "recipe"
        | "declarations"
        | "status"
        | "category"
        | "plant"
        | "manufacturer"
        | "image_url"
        | "declaration"
      >;
    }) =>
      api({ url: `/v1/manufacturers/${manufacturerId}/plants/${plantId}/products`, data: product }),
    [api],
  );
};

export const usePatchProduct = () => {
  // TODO: MOVE TO ACTUAL PATCHING / PARTIAL UPDATING
  const api = useApi<Product>({ method: "PUT" });

  return useCallback(
    ({
      manufacturerId,
      plantId,
      productId,
      product,
    }: {
      manufacturerId: string;
      plantId: string;
      productId: string;
      product: Partial<
        Omit<Product, "recipe" | "plant" | "manufacturer"> & { recipe: Omit<RecipeItem, "id">[] }
      >;
    }) =>
      api({
        url: `/v1/manufacturers/${manufacturerId}/plants/${plantId}/products/${productId}`,
        data: product,
      }),
    [api],
  );
};

export const useDeleteProduct = () => {
  const api = useApi<Product>({ method: "DELETE" });

  return useCallback(
    ({
      manufacturerId,
      plantId,
      productId,
    }: {
      manufacturerId: string;
      plantId: string;
      productId: string;
    }) =>
      api({ url: `/v1/manufacturers/${manufacturerId}/plants/${plantId}/products/${productId}` }),
    [api],
  );
};

export const usePostVerifyProduct = () => {
  const api = useApi<void>({ method: "POST" });

  return useCallback(
    ({
      manufacturerId,
      plantId,
      productId,
    }: {
      manufacturerId: string;
      plantId: string;
      productId: string;
    }) =>
      api({
        url: `/v1/manufacturers/${manufacturerId}/plants/${plantId}/products/${productId}/verify`,
      }),
    [api],
  );
};

export const useBulkUploadProducts = () => {
  const api = useApi<BulkUploadResult>({
    method: "POST",
    headers: { "Content-Type": "multipart/form-data" },
  });

  return useCallback(
    ({ manufacturerId, file }: { manufacturerId: string; file: File }) => {
      const formData = new FormData();
      formData.append("file", file);

      return api({
        url: `/v1/manufacturers/${manufacturerId}/products/bulk`,
        data: formData,
      });
    },
    [api],
  );
};
