import { ArrowDropDownOutlined } from "@mui/icons-material";
import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  createFilterOptions,
  FilterOptionsState,
  Popper,
  styled,
} from "@mui/material";
import { ComponentProps, forwardRef, SyntheticEvent, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { ComboBoxMultiItem } from "./ComboBoxMultiItem";
import { TextField } from "./TextField";

export type ComboBoxMultiOption = {
  id: string | number;
  label: string;
  isDisabled?: boolean;
  creatable?: boolean;
  group?: string;
};

type ComboBoxFieldProps = Omit<
  ComponentProps<typeof Autocomplete<ComboBoxMultiOption, true, false, false>>,
  "className" | "renderInput" | "renderOption" | "disabled" | "getOptionDisabled" | "size"
> & {
  label?: string;
  errorMessage?: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  placeholder?: string;
  size?: "medium" | "large";
  creatable?: boolean;
  wrapValueAt?: number;
};

const StyledPopper = styled(Popper)(() => ({
  "& .MuiAutocomplete-groupLabel": {
    backgroundColor: "#F5F5F5",
    color: "#737373",
    fontFamily: "CircularStd",
    fontWeight: "bold",
    textTransform: "uppercase",
  },
}));

export const ComboBoxMultiField = forwardRef<HTMLDivElement, ComboBoxFieldProps>(
  (
    {
      label,
      options,
      errorMessage,
      isDisabled,
      isRequired,
      placeholder,
      size,
      wrapValueAt = 3,
      creatable,
      ...props
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const defaultFilter = createFilterOptions<ComboBoxMultiOption>({
      matchFrom: "start",
    });

    const filterOptions = (
      options: ComboBoxMultiOption[],
      value: FilterOptionsState<ComboBoxMultiOption>,
    ): ComboBoxMultiOption[] => {
      const filtered = defaultFilter(options, value);

      if (!creatable) {
        return filtered;
      }

      const { inputValue } = value;
      // Suggest the creation of a new value
      const isExisting = options.some((option) => inputValue === option.label);
      if (inputValue !== "" && !isExisting) {
        filtered.push({
          label: `Add "${inputValue}"`,
          id: inputValue,
          creatable: true,
        });
      }

      return filtered;
    };

    const getOptionLabel = (option: string | ComboBoxMultiOption) => {
      // Value selected with enter, right from the input
      if (typeof option === "string") {
        return option;
      }
      // Add "xxx" option created dynamically
      if (option.creatable) {
        return String(option.id);
      }
      // Regular option
      return option.label;
    };

    const getOptionKey = useCallback((option: string | ComboBoxMultiOption) => {
      if (typeof option === "string") {
        return option;
      }

      return option.id;
    }, []);

    const onChange = (
      event: SyntheticEvent,
      value: (string | ComboBoxMultiOption)[],
      reason: AutocompleteChangeReason,
      details?: AutocompleteChangeDetails<ComboBoxMultiOption>,
    ) => {
      if (value && typeof value === "string") {
        props.onChange?.(
          event,
          [
            {
              id: value,
              label: value,
              creatable: true,
            },
          ],
          reason,
          details,
        );
      } else {
        props.onChange?.(event, value as ComboBoxMultiOption[], reason, details);
      }
    };

    return (
      <Autocomplete<ComboBoxMultiOption, true, false, boolean>
        multiple
        ref={ref}
        {...props}
        getOptionLabel={getOptionLabel}
        getOptionKey={getOptionKey}
        filterOptions={filterOptions}
        renderTags={() => null}
        freeSolo={creatable}
        onChange={onChange}
        clearOnBlur
        selectOnFocus
        handleHomeEndKeys
        disableCloseOnSelect
        groupBy={(option) => option.group || ""}
        PopperComponent={StyledPopper}
        renderOption={({ className, ...props }, opt) => {
          return (
            <ComboBoxMultiItem {...props} key={opt.id} creatable={opt.creatable}>
              {opt.label}
            </ComboBoxMultiItem>
          );
        }}
        options={options}
        getOptionDisabled={(option) => !!option.isDisabled}
        renderInput={({ InputProps: { ref }, inputProps: { className, ...inputProps } }) => {
          const value = props.value || [];

          const selectedValue =
            value.length >= wrapValueAt
              ? t("{{ count }} selected", { count: value.length })
              : value.map((opt) => opt.label).join(", ");

          return (
            <TextField
              ref={ref}
              isDisabled={isDisabled}
              inputProps={{
                ...inputProps,
                value: inputProps.value || selectedValue,
                addonRight: <ArrowDropDownOutlined />,
              }}
              errorMessage={errorMessage}
              label={label}
              placeholder={placeholder}
              isRequired={isRequired}
              size={size}
            />
          );
        }}
      />
    );
  },
);
