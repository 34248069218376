import { AddOutlined, UploadOutlined, WindowOutlined } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { Suspense, useEffect, useState } from "react";
import { FileTrigger } from "react-aria-components";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import tw from "tailwind-styled-components";
import { useBulkUploadProducts } from "../api/endpoints/products";
import { Button } from "../components/Button";
import { Link } from "../components/Link";
import { Loading } from "../components/Loading";
import { PageContainer } from "../components/PageContainer";
import { TopBar } from "../components/TopBar";
import { EmptyProductList } from "../page-components/products/EmptyProductList";
import { ProductCard } from "../page-components/products/ProductCard";
import { useActiveManufacturer, useProductCategories } from "../state/manufacturers";
import { useActiveOnboardingStep } from "../state/onboarding";
import { useActivePlant } from "../state/plants";
import { useActivePlantProducts } from "../state/products";
import { showSuccessToast } from "../util/toasts";

const Content = tw("div")`
  grid
  gap-5
  grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4
`;

export const Products = () => {
  const activePlant = useActivePlant();
  const { activeManufacturer } = useActiveManufacturer();

  const { t } = useTranslation();

  const { data: products } = useActivePlantProducts();
  const count = products.length;
  const queryClient = useQueryClient();

  const bulkUploadPlants = useBulkUploadProducts();
  const [uploadLoading, setUploadLoading] = useState(false);

  const handleUpload = async (files: FileList | null) => {
    if (!files) return;

    setUploadLoading(true);

    const { imported, updated, skipped, failed, errors } = await bulkUploadPlants({
      manufacturerId: activeManufacturer.id,
      file: files[0],
    });

    showSuccessToast(
      `Products uploaded successfully. Imported: ${imported}, Updated: ${updated}, Skipped: ${skipped}, Failed: ${failed} \n\n${errors.join("\n")}`,
    );

    queryClient.invalidateQueries({
      queryKey: [activeManufacturer.id, activePlant.id, "products"],
    });

    setUploadLoading(false);
  };

  const { productCategoriesMap } = useProductCategories();

  const navigate = useNavigate();
  const { step: onboardingStep } = useActiveOnboardingStep();
  useEffect(() => {
    if (onboardingStep < 3) {
      navigate("/onboarding");
    }
  }, [navigate, onboardingStep]);

  return (
    <PageContainer>
      <TopBar
        icon={<WindowOutlined />}
        title={t("Product Overview")}
        subtitle={
          <>
            {count} {t("products", { count })}
          </>
        }
        input={
          products.length > 0 ? (
            <>
              <FileTrigger onSelect={handleUpload}>
                <Button intent="secondary" isDisabled={uploadLoading}>
                  {uploadLoading ? <CircularProgress size="24px" /> : <UploadOutlined />}
                  {t("Upload Products")}
                </Button>
              </FileTrigger>
              <Link href="/edit/product-details">
                <AddOutlined />
                {t("New Product")}
              </Link>
            </>
          ) : undefined
        }
      />
      <div className="flex-grow flex flex-col gap-8 py-8">
        <Suspense fallback={<Loading />}>
          {products.length > 0 && (
            <Content>
              {products.map((item) => (
                <ProductCard
                  key={item.id}
                  item={item}
                  productCategoriesMap={productCategoriesMap}
                />
              ))}
            </Content>
          )}
          {products.length === 0 && <EmptyProductList activePlant={activePlant} />}
        </Suspense>
      </div>
    </PageContainer>
  );
};
