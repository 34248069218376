import { ajvResolver } from "@hookform/resolvers/ajv";
import { isAxiosError } from "axios";
import { useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { DynamicFormJSONSchema, Product } from "../../api/types";
import { Heading3, Text16 } from "../../components/Typography";
import { ComboBoxFieldConnected } from "../../form-components/ComboBoxFieldConnected";
import { JSONSchemaFieldConnected } from "../../form-components/JSONSchemaFieldConnected";
import { NumberFieldConnected } from "../../form-components/NumberFieldConnected";
import {
  HARD_CODED_CONCRETE_CATEGORY_ID,
  HARD_CODED_PRE_CAST_CONCRETE_CATEGORY_ID,
} from "../../state/HARD_CODED";
import { useSelectedProduct, useUpdateProduct } from "../../state/products";
import { useGetLinkWithParams } from "../../url/useGetLinkWithParams";
import { exists } from "../../util/commonUtil";
import { showErrorToast } from "../../util/toasts";
import { useWarnBeforeUnload } from "../../util/useWarnBeforeUnload";
import { EditFlowNav } from "./EditFlowNav";

type ConcreteScenario = {
  application: ReturnType<typeof useConcreteApplicationOptions>[number]["id"];
};

type Scenarios = ConcreteScenario | null;

interface ProductUsageForm {
  transportDistance: number;
  scenario: Scenarios;
}

export const ProductSpecs = () => {
  const { data: selectedProduct } = useSelectedProduct();

  if (!selectedProduct) return null;

  return <ProductSpecsForm selectedProduct={selectedProduct} />;
};

const ProductSpecsForm = ({ selectedProduct }: { selectedProduct: Product }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const getLinkWithParams = useGetLinkWithParams();
  const onPrev = () => navigate(getLinkWithParams("/edit/product-details"));

  const { mutate: updateProduct, isPending: loading } = useUpdateProduct();
  const [searchParams] = useSearchParams();

  const isConcrete =
    selectedProduct.category.id === HARD_CODED_CONCRETE_CATEGORY_ID ||
    selectedProduct.category.id === HARD_CODED_PRE_CAST_CONCRETE_CATEGORY_ID;

  const methods1 = useForm<Record<string, unknown>>({
    // @ts-expect-error TODO: Try and fix https://ajv.js.org/guide/typescript.html#utility-types-for-schemas
    resolver: ajvResolver(selectedProduct.tech_specs_schema, {
      strict: false,
    }),
  });
  const reset = methods1.reset;

  const methods2 = useForm<ProductUsageForm>({
    defaultValues: selectedProduct
      ? {
          transportDistance: selectedProduct.distance_a4_km ?? NaN,
          scenario: isConcrete ? selectedProduct.scenario : null,
        }
      : undefined,
  });

  useWarnBeforeUnload(methods1.formState.isDirty || methods2.formState.isDirty);

  const onSubmit = async () => {
    if (!selectedProduct) return;

    [methods1, methods2].forEach((method) => method.clearErrors());

    const formsValid = (await Promise.all([methods1.trigger(), methods2.trigger()])).every(Boolean);

    if (!formsValid) return;

    updateProduct(
      {
        productId: selectedProduct.id,
        product: {
          ...selectedProduct,
          tech_specs: Object.fromEntries(
            Object.entries(methods1.getValues()).filter(([, value]) => exists(value)),
          ),
          distance_a4_km: selectedProduct.category?.declared_modules.a4
            ? methods2.getValues("transportDistance")
            : undefined,
          scenario: isConcrete ? methods2.getValues("scenario") : undefined,
          production_process_id: selectedProduct.production_process_id,
        },
      },
      {
        onSuccess: () => {
          navigate({
            pathname: "/edit/product-production-process",
            search: searchParams.toString(),
          });
        },
        onError: (error) => {
          if (isAxiosError(error) && error.response?.data?.message) {
            // Apparently we have a technical error message from the server here
            // that's user-friendly enough to display it
            showErrorToast(error.response?.data?.message);
          } else {
            console.error(error);
          }
        },
      },
    );
  };

  useEffect(
    function populateForm() {
      if (!selectedProduct || !selectedProduct.tech_specs) return;

      reset(selectedProduct.tech_specs);
    },
    [selectedProduct, reset],
  );

  return (
    <>
      <div className="flex flex-col h-full pb-20">
        <div className="flex-grow">
          <div className="flex flex-col gap-8">
            <FormProvider {...methods1}>
              <form noValidate>
                <TechSpecsFields schema={selectedProduct.tech_specs_schema} />
              </form>
            </FormProvider>

            {selectedProduct.category?.declared_modules.a4 && (
              <>
                <div className="mx-auto w-full max-w-6xl space-y-4">
                  <div className="flex flex-col gap-2">
                    <Heading3>{t("Product Usage")}</Heading3>
                    <Text16>
                      {t(
                        "We use the following information to build scenarios for your product's environmental data after leaving the factory gate.",
                      )}
                    </Text16>
                  </div>
                  <div className="flex-grow">
                    <FormProvider {...methods2}>
                      <form noValidate className="grid grid-cols-[2fr_1fr] gap-6">
                        {isConcrete && <ConcreteApplicationField />}
                        <NumberFieldConnected
                          name="transportDistance"
                          rules={{ min: 0 }}
                          label={t("Average Distance to Customer")}
                          isRequired
                          inputProps={{
                            addonRight: "km",
                          }}
                        />
                      </form>
                    </FormProvider>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <EditFlowNav onPrev={onPrev} onNext={onSubmit} nextSubmit={false} nextLoading={loading} />
      </div>
    </>
  );
};

const TechSpecsFields = ({ schema }: { schema: DynamicFormJSONSchema }) => {
  return (
    <div className="flex-grow flex flex-col gap-10 py-2 mx-auto w-full max-w-6xl">
      <div className="grid xl:grid-cols-3 grid-cols-2 gap-6">
        {Object.entries(schema.properties).map(([fieldname, fieldConfig]) => (
          <JSONSchemaFieldConnected
            key={fieldname}
            schema={schema}
            fieldName={fieldname}
            fieldConfig={fieldConfig}
          />
        ))}
      </div>
    </div>
  );
};

const useConcreteApplicationOptions = () => {
  const { t } = useTranslation();

  return useMemo(
    () => [
      { label: t("Engineering structure - Exposed to rain"), id: "STRUCTURE_EXPOSED" as const },
      {
        label: t("Engineering structure - Protected from rain"),
        id: "STRUCTURE_PROTECTED" as const,
      },
      { label: t("Engineering structure - In the ground"), id: "STRUCTURE_GROUND" as const },
      { label: t("Building - Outside - Exposed to rain"), id: "BUILDING_OUTSIDE_EXPOSED" as const },
      {
        label: t("Building - Outside - Protected from rain"),
        id: "BUILDING_OUTSIDE_PROTECTED" as const,
      },
      {
        label: t("Building - Dry interior - With paint / wallpaper"),
        id: "BUILDING_DRY_PAINT" as const,
      },
      {
        label: t("Building - Dry interior - Without paint / wallpaper"),
        id: "BUILDING_DRY_NOPAINT" as const,
      },
      { label: t("Building - In the ground"), id: "BUILDING_GROUND" as const },
    ],
    [t],
  );
};

const ConcreteApplicationField = () => {
  const { t } = useTranslation();

  return (
    <ComboBoxFieldConnected
      name="scenario.application"
      label={t("Application Scenario")}
      isRequired
      options={useConcreteApplicationOptions()}
    />
  );
};
