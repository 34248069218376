import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useGetLciaResults } from "../api/endpoints/declarations";
import { Product } from "../api/types";
import { showErrorToast } from "../util/toasts";

export const useLCIAResults = ({ product }: { product: Product }) => {
  const { t } = useTranslation();
  const getLciaResults = useGetLciaResults();

  const { error, ...props } = useQuery({
    queryFn: () =>
      getLciaResults({
        manufacturerId: product.manufacturer_id,
        plantId: product.plant_id,
        productId: product.id,
      }),
    queryKey: [product.manufacturer_id, product.plant_id, product.id, "lciaResults"],
  });

  useEffect(() => {
    if (error) {
      showErrorToast(t("Failed to load data for life cycle impact assessment, please try again."));
    }
  }, [error, t]);

  return {
    ...props,
    error,
  };
};
