import { ArrowBack, DeleteOutlined } from "@mui/icons-material";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useGetLciaResults } from "../../../api/endpoints/declarations";
import {
  useCreatePrechainProduct,
  useUpdatePrechainProduct,
} from "../../../api/endpoints/prechain-products";
import { useGetEndsOfScale } from "../../../api/endpoints/product-categories";
import { EndOfScale, LciaResults, PrechainProduct } from "../../../api/types";
import { Button } from "../../../components/Button";
import { ConfirmationModal } from "../../../components/ConfirmationModal";
import { PageContainer } from "../../../components/PageContainer";
import { TextField } from "../../../components/TextField";
import { TopBar } from "../../../components/TopBar";
import { Text16 } from "../../../components/Typography";
import { ComboBoxFieldConnected } from "../../../form-components/ComboBoxFieldConnected";
import { TextFieldConnected } from "../../../form-components/TextFieldConnected";
import { coreIndicators, filterImpact } from "../../../lca/impact";
import { useElementaries } from "../../../state/elementaries";
import { useActiveManufacturer } from "../../../state/manufacturers";
import { usePrechainProducts } from "../../../state/materials";
import { useActivePlant, usePlants } from "../../../state/plants";
import { useDeletePrechainProductById } from "../../../state/prechainProducts";
import { useProducts } from "../../../state/products";
import { showSuccessToast } from "../../../util/toasts";
import { ImpactFactors } from "../../product-detail/ImpactFactors";
import { RadarChart } from "../../product-detail/RadarChart";
import { DEFAULT_TRANSPORTATION, TransportationForm } from "./TransportationForm";
import { TransportationFormFields } from "./types";

interface Fields {
  plantId: string;
  productId: string;
  category: string;
  material: string;
  transportation: TransportationFormFields[];
}

export const PrechainProductForm = ({
  prechainProduct,
}: {
  prechainProduct: PrechainProduct | null;
}) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const existingProducts = usePrechainProducts();

  const { activeManufacturer } = useActiveManufacturer();

  const methods = useForm<Fields>({
    defaultValues: {
      plantId: prechainProduct?.product?.plant_id || "",
      productId: prechainProduct?.product_id || "",
      category: "",
      material: "",
      transportation: [
        {
          modality: "truck",
          distance: undefined,
        },
      ],
    },
  });

  useEffect(
    function setInitialValues() {
      if (prechainProduct) {
        methods.reset({
          plantId: prechainProduct.product.plant_id,
          productId: prechainProduct.product.id,
          transportation: prechainProduct.transportation,
        });
      }
    },
    [prechainProduct, methods],
  );

  const { data: plants } = usePlants();
  const activePlant = useActivePlant();
  const plantOptions = plants
    .sort((a, b) => a.name.localeCompare(b.name))
    .map(({ id, name }) => ({ id, label: name }));

  // On plant change, fetch products and reset product field
  const plantId = methods.watch("plantId");
  const { data: products = [] } = useProducts({ plantId });

  const productOptions = products
    .filter((x) => x.status === "draft")
    .filter(
      (x) =>
        x.id === prechainProduct?.product_id ||
        existingProducts.every((p) => p.product_id !== x.id),
    ) // Filter out existing products
    .map(({ id, name }) => ({ id, label: name }));

  useEffect(() => {
    if (activePlant.id === plantId) {
      methods.setValue("transportation", []);
    } else {
      methods.setValue("transportation", DEFAULT_TRANSPORTATION);
    }
  }, [activePlant, plantId, methods]);

  const { elementariesMap } = useElementaries();

  const [lciaResults, setLciaResults] = useState<LciaResults | null>(null);
  const [endsOfScale, setEndsOfScale] = useState<EndOfScale[] | null>(null);
  const getLciaResults = useGetLciaResults();
  const getEndsOfScale = useGetEndsOfScale();

  // On product change, set category and material fields, and fetch LCIA results
  const productId = methods.watch("productId");
  useEffect(() => {
    setLciaResults(null);
    setEndsOfScale(null);

    if (productId && plantId) {
      const product = products.find((p) => p.id === productId);
      if (!product) return;
      getLciaResults({ manufacturerId: activeManufacturer.id, plantId, productId }).then(
        (results) => {
          setLciaResults(results);
        },
      );
      getEndsOfScale(product.category.id).then((ends) => {
        setEndsOfScale(ends);
      });

      methods.setValue("category", elementariesMap[product.elementary_id].category);
      methods.setValue("material", elementariesMap[product.elementary_id].name_en);
    } else {
      methods.setValue("category", "");
      methods.setValue("material", "");
    }
  }, [
    activeManufacturer,
    elementariesMap,
    getEndsOfScale,
    getLciaResults,
    methods,
    plantId,
    productId,
    products,
  ]);

  const createPrechainProduct = useCreatePrechainProduct();
  const updatePrechainProduct = useUpdatePrechainProduct();
  const onSubmit = useCallback(
    async (data: Fields) => {
      const transportation = data.transportation
        .filter((t) => !!t.distance)
        .map((t) => ({
          load: null,
          utilization: null,
          modality: t.modality,
          distance: t.distance!,
        }));

      if (prechainProduct) {
        await updatePrechainProduct({
          manufacturerId: activeManufacturer.id,
          plantId: activePlant.id,
          prechainProduct: {
            ...prechainProduct,
            transportation,
          },
        });
      } else {
        await createPrechainProduct({
          manufacturerId: activeManufacturer.id,
          plantId: activePlant.id,
          prechainProduct: {
            product_id: data.productId,
            transportation,
          },
        });
      }

      queryClient.invalidateQueries({
        queryKey: [activeManufacturer.id, activePlant.id, "materials"],
      });

      navigate("/suppliers-and-materials");
      showSuccessToast(
        prechainProduct ? t("Product ingredient changed") : t("New product ingredient created"),
      );
    },
    [
      activePlant,
      prechainProduct,
      queryClient,
      activeManufacturer.id,
      navigate,
      t,
      updatePrechainProduct,
      createPrechainProduct,
    ],
  );

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const deletePrechainProduct = useDeletePrechainProductById();

  const deleteProduct = useCallback(async () => {
    if (!prechainProduct) return;

    setIsDeleting(true);

    try {
      await deletePrechainProduct(prechainProduct.id);
      queryClient.invalidateQueries({
        queryKey: [activeManufacturer.id, activePlant.id, "materials"],
      });
      navigate("/suppliers-and-materials");
      showSuccessToast(t("Product ingredient deleted"));
    } catch (error) {
      console.error(error);
    } finally {
      setIsDeleting(false);
      setShowDeleteConfirmation(false);
    }
  }, [
    activeManufacturer.id,
    activePlant.id,
    deletePrechainProduct,
    navigate,
    prechainProduct,
    queryClient,
    t,
  ]);

  return (
    <FormProvider {...methods}>
      <PageContainer $as="form" onSubmit={methods.handleSubmit(onSubmit)} noValidate>
        <TopBar
          title={
            prechainProduct
              ? t("Edit product (as an ingredient)")
              : t("Add new product (as an ingredient)")
          }
          icon={<ArrowBack />}
          onIconClick={() => navigate("/suppliers-and-materials")}
          input={
            prechainProduct ? (
              <>
                <Button
                  type="button"
                  onPress={() => setShowDeleteConfirmation(true)}
                  isDisabled={isDeleting}
                  intent="tertiary"
                >
                  {t("Delete Product")}
                  <DeleteOutlined />
                </Button>
                <Button type="submit" intent="primary" isDisabled={isDeleting}>
                  {t("Update Product")}
                </Button>
              </>
            ) : (
              <Button type="submit">{t("Save Product")}</Button>
            )
          }
        />
        <div className="flex flex-col my-8 h-full">
          <div className="w-1/2 flex flex-row gap-3 mb-6 pr-6">
            <TextField
              placeholder={t("Supplier")}
              value={activeManufacturer.name}
              label={t("Supplier")}
              isRequired
              isDisabled
            />
            <ComboBoxFieldConnected
              name="plantId"
              isRequired
              options={plantOptions}
              isDisabled={!!prechainProduct}
              placeholder={t("Specify plant")}
              label={t("Your Production Plant")}
            />
          </div>
          <ComboBoxFieldConnected
            name="productId"
            isRequired
            options={productOptions}
            isDisabled={!!prechainProduct || !plantId}
            placeholder={t("Name of the product produced in your plant")}
            size="large"
            label={t("Product Name")}
          />
          <div className="flex h-full divide-x">
            <div className="w-1/2 pt-6 pr-6 flex flex-col gap-6">
              <div className="grid grid-cols-2 gap-4">
                <TextFieldConnected
                  name="category"
                  isRequired
                  placeholder={t("Category")}
                  label={t("Category")}
                  isDisabled
                />
                <TextFieldConnected
                  name="material"
                  isRequired
                  placeholder={t("Material")}
                  label={t("Material")}
                  isDisabled
                />
              </div>
              {methods.watch("transportation").length > 0 && <TransportationForm />}
            </div>
            <div className="w-1/2 flex flex-col gap-6 pt-6 pl-6">
              {lciaResults && endsOfScale && (
                <>
                  <div className="mx-auto max-w-96 max-h-96 w-full h-full">
                    <RadarChart
                      impacts={filterImpact(lciaResults.impact_summary.a1_a3, coreIndicators)}
                      endsOfScale={endsOfScale}
                    />
                  </div>
                  <div className="flex items-center">
                    <ImpactFactors
                      impacts={filterImpact(lciaResults.impact_summary.a1_a3, coreIndicators)}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        {showDeleteConfirmation && (
          <ConfirmationModal
            title={t("Delete product ingredient")}
            content={<Text16>{t("Confirm the deletion")}</Text16>}
            danger
            onConfirm={() => deleteProduct()}
            onCancel={() => setShowDeleteConfirmation(false)}
          />
        )}
      </PageContainer>
    </FormProvider>
  );
};
