import { Add, Clear } from "@mui/icons-material";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Button } from "../../../components/Button";
import { ComboBoxFieldConnected } from "../../../form-components/ComboBoxFieldConnected";
import { NumberFieldConnected } from "../../../form-components/NumberFieldConnected";
import { FormFields, TransportationFormFields } from "./types";

export const DEFAULT_TRANSPORTATION = [{ modality: "truck", distance: null }];

const MODALITY_OPTIONS = [
  {
    label: "🚚 On Land",
    id: "truck",
  },
  {
    label: "🚅 On Rails",
    id: "train",
  },
  {
    label: "🚢 On Water",
    id: "ship",
  },
];

export const TransportationForm = () => {
  const { t } = useTranslation();
  const methods = useFormContext<{ transportation: TransportationFormFields[] }>();
  const transportation = methods.watch("transportation");

  return (
    <div className={`flex flex-col ${transportation.length > 1 ? "gap-4" : "gap-6"}`}>
      {transportation.map((_, index, array) => (
        <div key={index} className="flex flex-col gap-1 items-start">
          <div className="grid grid-cols-4 items-start gap-6 w-full">
            <ComboBoxFieldConnected
              name={`transportation.${index}.modality`}
              isRequired
              options={MODALITY_OPTIONS.filter(
                (opt) =>
                  !array.some((item, itemIndex) => itemIndex !== index && item.modality === opt.id),
              )}
              label={t("Transport")}
            />
            <NumberFieldConnected<FormFields>
              name={`transportation.${index}.distance`}
              isRequired
              minValue={0}
              label={t("Distance")}
              inputProps={{ addonRight: "km" }}
            />
          </div>
          {array.length > 1 && (
            <Button
              type="button"
              size="small"
              intent="tertiary"
              onPress={() => {
                const newArray = array.filter((_, itemIndex) => itemIndex !== index);
                methods.setValue("transportation", newArray);
              }}
            >
              <Clear />
              {t("Remove this transport")}
            </Button>
          )}
        </div>
      ))}
      {transportation.length < MODALITY_OPTIONS.length && (
        <div>
          <Button
            intent="secondary"
            type="button"
            onPress={() => {
              methods.setValue("transportation", [
                ...transportation,
                {
                  modality:
                    MODALITY_OPTIONS.find(
                      (opt) => !transportation.some((x) => x.modality === opt.id),
                    )?.id ?? "",
                  distance: 0,
                },
              ]);
            }}
          >
            <Add />
            {t("Add transport")}
          </Button>
        </div>
      )}
    </div>
  );
};
