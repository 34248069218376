import {
  Business,
  Circle,
  Edit,
  FactoryOutlined,
  Search,
  TokenOutlined,
  WindowOutlined,
} from "@mui/icons-material";
import { ReactNode, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ProgressIndicatorIcon } from "../components/ProgressIndicatorIcon";
import { useAdminMode } from "../state/admin";
import { useActiveOnboardingStep } from "../state/onboarding";

export interface NavbarItemType {
  name: string;
  path: string;
  icon?: ReactNode;
  children?: NavbarItemType[]; // Supporting a single level of children for now
  hideNavbar?: boolean;
  step?: number;
}

export const useAllPages = (): Record<string, NavbarItemType> => {
  const { t } = useTranslation();

  return useMemo(
    () => ({
      products: { name: t("Products"), path: "/", icon: <WindowOutlined /> },
      onboarding: { name: t("Data setup"), path: "/onboarding", icon: <Circle /> },
      plant: { name: t("Production"), path: "/production", icon: <FactoryOutlined /> },
      materials: {
        name: t("Materials"),
        path: "/suppliers-and-materials",
        icon: <TokenOutlined />,
      },
      companySettings: {
        name: t("Company Settings"),
        path: "/company-settings",
        icon: <Business />,
      },
      edit: {
        name: t("Edit"),
        path: "/edit",
        icon: <Edit />,
        hideNavbar: true,
      },
      editProductDetails: {
        name: t("Create a product profile"),
        path: "/edit/product-details",
        hideNavbar: true,
        step: 1,
      },
      editProductSpecs: {
        name: t("Create a product profile"),
        path: "/edit/product-specs",
        hideNavbar: true,
        step: 2,
      },
      editProductProductionProcess: {
        name: t("Choose a production process"),
        path: "/edit/product-production-process",
        hideNavbar: true,
        step: 3,
      },
      editProductMaterials: {
        name: t("Add recipe"),
        path: "/edit/product-recipe",
        hideNavbar: true,
        step: 4,
      },
      editProcessFlow: {
        name: t("Model Flow"),
        path: "/edit/process-flow",
        hideNavbar: true,
      },
      editProcessMaterials: {
        name: t("Model Materials"),
        path: "/edit/process-materials",
        hideNavbar: true,
      },
      search: { name: t("Search"), path: "/search", icon: <Search /> },
      newProductionProcess: {
        name: t("Add production process"),
        path: "/production/processes/new",
        hideNavbar: true,
      },
      editProductionProcess: {
        name: t("Add production process"),
        path: "/production/processes/:id/edit",
        hideNavbar: true,
      },
      editPlant: {
        name: t("Edit plant"),
        path: "/manage-plants/edit",
        hideNavbar: true,
      },
      addPlant: {
        name: t("Add plant"),
        path: "/manage-plants/new",
        hideNavbar: true,
      },
    }),
    [t],
  );
};

export const useNavbarItems = (): NavbarItemType[] => {
  const adminMode = useAdminMode();

  const pages = useAllPages();
  const { step: onboardingStep } = useActiveOnboardingStep();

  const isOnboarding = onboardingStep < 4;

  return useMemo(
    () => [
      ...(isOnboarding
        ? [
            {
              ...pages.onboarding,
              icon: <ProgressIndicatorIcon done={onboardingStep / 4} />,
            },
          ]
        : []),
      ...(onboardingStep > 2 ? [pages.products] : []),
      ...(onboardingStep > 1 ? [pages.plant] : []),
      pages.materials,
      ...(!isOnboarding ? [pages.search] : []),
      ...(adminMode
        ? [
            {
              ...pages.edit,
              children: [
                pages.editProductDetails,
                pages.editProductSpecs,
                pages.editProductDetails,
                pages.editProcessFlow,
                pages.editProcessMaterials,
                pages.editLCAResults,
                pages.editVerification,
                pages.editPublication,
              ],
            },
          ]
        : []),
    ],
    [isOnboarding, pages, onboardingStep, adminMode],
  );
};
