import { ClearOutlined, WindowOutlined } from "@mui/icons-material";
import { Suspense, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation } from "react-router-dom";
import { Link } from "../../components/Link";
import { PageContainer } from "../../components/PageContainer";
import { ProgressBar } from "../../components/ProgressBar";
import { TopBar } from "../../components/TopBar";
import { Heading3, Label } from "../../components/Typography";
import { useAllPages } from "../../config/useNavbarItems";
import { CATEGORIES_WITHOUT_RECIPE } from "../../state/HARD_CODED";
import { useSelectedProduct } from "../../state/products";

// To be adjusted if there's a change to the pages or to the navigation architecture
const MAX_STEPS = 4;

export const Edit = () => {
  const { t } = useTranslation();

  const { data: selectedProduct } = useSelectedProduct();

  const activePath = useLocation().pathname;
  const navbarItems = Object.values(useAllPages());
  const activeItem = useMemo(
    () => navbarItems.find((item) => item.path === activePath),
    [activePath, navbarItems],
  );
  const totalSteps = useMemo(
    // For some product categories, there is no "create recipe" step
    () =>
      selectedProduct && CATEGORIES_WITHOUT_RECIPE.includes(selectedProduct.category.id)
        ? MAX_STEPS - 1
        : MAX_STEPS,
    [selectedProduct],
  );

  return (
    <PageContainer>
      <TopBar
        icon={<WindowOutlined />}
        title={selectedProduct ? t("Edit Product") : t("New Product")}
        centerSection={
          activeItem?.step ? <ProgressBar step={activeItem.step} total={totalSteps} /> : undefined
        }
        input={
          <Link intent="tertiaryFlat" href="/products">
            {t("Cancel process")}
            <ClearOutlined />
          </Link>
        }
      />
      <div className="flex-grow flex flex-col gap-5 py-8 overflow-hidden">
        <div className="flex-grow flex flex-col gap-8 overflow-hidden">
          <div className="space-y-1">
            {activeItem?.step && (
              <Label className="text-center">
                {t("Step {{ step }} / {{ total }}", { step: activeItem.step, total: totalSteps })}
              </Label>
            )}
            <Heading3 className="text-center">{activeItem?.name}</Heading3>
          </div>
          <Suspense>
            <Outlet />
          </Suspense>
        </div>
      </div>
    </PageContainer>
  );
};
