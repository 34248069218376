import { LaunchOutlined } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import { GridColDef } from "@mui/x-data-grid";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import type { EPDSearchResult } from "../api/types";
import { DataGrid } from "../components/DataGrid";

// Roughly measured based on data grid height with 10 rows and 35px row height
const MIN_HEIGHT = 460;

export const SearchResults = ({
  results,
  searchedLanguage,
}: {
  results: EPDSearchResult[];
  searchedLanguage?: string;
}) => {
  const { t } = useTranslation();

  const columns: GridColDef[] = useMemo(() => {
    return [
      searchedLanguage === "de"
        ? {
            field: "name_de",
            headerName: "Name",
            width: 300,
          }
        : {
            field: "name_en",
            headerName: "Name",
            width: 300,
          },
      {
        field: "manufacturer",
        headerName: t("Manufacturer"),
        width: 200,
      },
      {
        field: "gwp_total",
        headerName: "CO2e (kg)",
        type: "number",
        width: 110,
        renderCell: (params) => {
          return params.row.lcia_gwp_total?.toFixed(2) ?? "-";
        },
      },
      {
        field: "declared_value",
        headerName: t("Declared Unit"),
        width: 100,
        renderCell: (params) => {
          return `${parseFloat(params.row.declared_value).toFixed(0)} ${params.row.declared_unit}`;
        },
      },
      // {
      //   field: "geography",
      //   headerName: "Geography",
      //   width: 150,
      // },
      {
        field: "dataset_type",
        headerName: t("Dataset"),
        width: 80,
      },
      {
        field: "valid_until",
        headerName: t("Valid Until"),
        width: 100,
      },
      {
        field: "item_source_url",
        headerName: "Details",
        width: 80,
        renderCell: (params) => {
          return (
            <IconButton
              onClick={() => {
                const url = params.row.item_source_url;
                window.open(url, "_blank");
              }}
            >
              <LaunchOutlined />
            </IconButton>
          );
        },
      },
    ];
  }, [t, searchedLanguage]);

  return (
    <div className="grid" style={{ minHeight: MIN_HEIGHT }}>
      <DataGrid
        rows={results}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 100,
            },
          },
        }}
        pageSizeOptions={[100]}
        disableRowSelectionOnClick
        localeText={{
          noRowsLabel: t("Enter a search term to browse the Emidat database."),
        }}
      />
    </div>
  );
};
