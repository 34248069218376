import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDeleteProductionProcess } from "../../api/endpoints/production-processes";
import { ProductionProcess } from "../../api/types";
import { Button } from "../../components/Button";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "../../components/Modal";
import { showSuccessToast } from "../../util/toasts";

export const DeleteProcessModal = ({ process }: { process: ProductionProcess }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { mutate: deleteProcess } = useDeleteProductionProcess();

  const handleConfirm = () => {
    deleteProcess(process.id, {
      onSuccess: () => {
        showSuccessToast(t("Successfully deleted production process"));
        navigate("/production");
      },
    });
  };

  return (
    <Modal>
      {({ close }) => (
        <>
          <ModalHeader title={t("Delete Production Process")} onClose={close} />
          <ModalBody>{t("Are you sure you want to delete this production process?")}</ModalBody>
          <ModalFooter>
            <Button intent="secondary" type="button" onPress={close}>
              {t("Cancel")}
            </Button>
            <Button intent="primary" onPress={handleConfirm}>
              {t("Confirm")}
            </Button>
          </ModalFooter>
        </>
      )}
    </Modal>
  );
};
