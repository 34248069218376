import { useTranslation } from "react-i18next";
import { LciaResults } from "../../api/types";
import { Heading3, Label12, Text14 } from "../../components/Typography";
import { impactByIndicator } from "../../lca/impact";
import { formatUnit } from "../../util/format";

export const ProductStats = ({
  lciaResults,
  baseUnit,
}: {
  lciaResults: LciaResults;
  baseUnit: string;
}) => {
  const { t } = useTranslation();

  const embodiedCarbon = impactByIndicator(lciaResults.impact_summary.a1_a3, "GWP-total");
  const pert = impactByIndicator(lciaResults.impact_summary.a1_a3, "PERT");
  const penrt = impactByIndicator(lciaResults.impact_summary.a1_a3, "PENRT");
  const energyUseValue = pert.value + penrt.value;

  const waterUse = impactByIndicator(lciaResults.impact_summary.a1_a3, "FW");

  if (waterUse.unit !== "m3") console.warn("Unexpected unit for FW");
  const waterUseLiter = {
    ...waterUse,
    value: waterUse.value * 1000,
    unit: "liters",
  };

  return (
    <div className="grid grid-cols-3 divide-x divide-neutral-300">
      {[
        {
          ...embodiedCarbon,
          indicator: t("Embodied Carbon"),
          description: t("This is the carbon footprint of this product."),
        },
        {
          indicator: t("Energy Use"),
          value: energyUseValue,
          unit: "MJ",
          description: t("The total amount of energy from renweable and non-renewable sources."),
        },
        {
          ...waterUseLiter,
          indicator: "Water Use",
          description: t("The total amount of water used for manufacturing this product."),
        },
      ].map((item) => (
        <div key={item.indicator} className="flex flex-col justify-start gap-3 px-4 first:pl-0">
          <div className="flex flex-col justify-start gap-1">
            <Label12>{item.indicator}</Label12>
            <Heading3>
              {item.value?.toPrecision(4)} {formatUnit(item.unit)}/{formatUnit(baseUnit)}
            </Heading3>
          </div>
          <Text14>{item.description}</Text14>
        </div>
      ))}
    </div>
  );
};
