import { DoNotDisturb, Home, WindowOutlined } from "@mui/icons-material";
import { FallbackRender } from "@sentry/react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { PageContainer } from "../../components/PageContainer";
import { TopBar } from "../../components/TopBar";
import { ManageManufacturers } from "../../page-components/company-settings/ManageManufacturers";
import { NoPlantError } from "../../state/plants";
import { AddPlant } from "../AddPlant";

export const ErrorPage: FallbackRender = ({ error, resetError }) => {
  const { t } = useTranslation();

  console.error(error);

  // -------------
  // Initial manufacturer setup
  // -------------
  // We're doing this as an error case,
  // so that all other components can simply call
  // useActiveManufacturer and it will always be defined.
  if (axios.isAxiosError(error) && error.config?.url === "/v1/me/manufacturers") {
    return (
      <PageContainer className="w-full">
        <TopBar
          icon={<WindowOutlined />}
          title={t("Company Setup")}
          subtitle={t("Please set up your company details.")}
          hideNavigation
        />
        <div className="my-12 w-full flex items-center justify-center">
          <ManageManufacturers onCreateSuccess={resetError} />
        </div>
      </PageContainer>
    );
  }

  // -------------
  // Initial plant setup
  // -------------
  // We're doing this as an error case,
  // so that all other components can simply call
  // useActivePlant and it will always be defined.
  if (error instanceof NoPlantError) {
    return <AddPlant onCreateSuccess={resetError} />;
  }

  return (
    <div className="w-full flex flex-col gap-3 h-screen items-center justify-center">
      <DoNotDisturb className="text-gray-300" sx={{ fontSize: 100 }} />
      <h2 className="text-xl">{t("Something went wrong.")}</h2>
      <a className="flex gap-3 items-center text-xl px-5 py-2 text-neutral-500 font-bold" href="/">
        <Home />
        {t("Restart")}
      </a>
    </div>
  );
};
