import {
  ArrowBack,
  Circle,
  DeleteOutlined,
  DescriptionOutlined,
  EditOutlined,
} from "@mui/icons-material";
import { Dispatch, SetStateAction, Suspense } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Product } from "../../api/types";
import { Button } from "../../components/Button";
import { TabSwitch } from "../../components/TabSwitch";
import { TopBar } from "../../components/TopBar";
import { useDeclaration } from "../../state/declarations";
import { exists } from "../../util/commonUtil";
import { useProductStatus } from "../products/ProductStatusIndicator";

export const ProductDetailTopBar = ({
  product,
  activeTab,
  setActiveTab,
  onEdit,
  onDelete,
  onOpenSidePanel,
}: {
  product: Product;
  activeTab: "passport" | "analyse";
  setActiveTab: Dispatch<SetStateAction<"passport" | "analyse">>;
  onEdit: () => void;
  onDelete: () => void;
  onOpenSidePanel: () => void;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <TopBar
      variant="concrete"
      icon={
        <Button intent="secondary" square onPress={() => navigate("/")}>
          <ArrowBack />
        </Button>
      }
      title={t("Product Details")}
      subtitle={
        <span className="flex items-center gap-2">
          <span>{t("Status")}:</span>
          <span>{useProductStatus(product.status)}</span>
        </span>
      }
      extra={
        <TabSwitch
          options={[
            { label: "Passport", value: "passport" as const },
            { label: "Analyse", value: "analyse" as const },
          ]}
          activeTab={activeTab}
          setActiveTab={(tab) => setActiveTab(tab as "passport" | "analyse")}
        />
      }
      input={
        <>
          {(product.status === "draft" || product.status === "incomplete") && (
            <>
              <Button intent="tertiary" onPress={onDelete}>
                {t("Delete product")}
                <DeleteOutlined />
              </Button>
              <Button intent="secondary" onPress={onEdit}>
                {t("Edit product")}
                <EditOutlined />
              </Button>
            </>
          )}
          {product.category.can_produce_epd && (
            <Suspense>
              <MainButton onPress={onOpenSidePanel} product={product} />
            </Suspense>
          )}
        </>
      }
    />
  );
};

const MainButton = ({ product, onPress }: { product: Product; onPress: () => void }) => {
  const { t } = useTranslation();
  const { data: declaration } = useDeclaration({
    productId: product.id,
    plantId: product.plant_id,
    manufacturerId: product.manufacturer_id,
  });

  const hasPublished = false; // TODO: Implement this
  const hasDocuments = exists(declaration);

  return (
    <Button onPress={onPress}>
      {hasPublished
        ? t("EPD verified & published")
        : hasDocuments
          ? t("Documents")
          : t("Generate EPD")}
      {hasPublished ? <Circle /> : <DescriptionOutlined />}
    </Button>
  );
};
