import { useMutation } from "@tanstack/react-query";
import { useCallback } from "react";
import { Declaration, LciaResults, Reason } from "../types";
import { apiLink, useApi } from "../useApi";

export const useGetDeclaration = () => {
  const api = useApi<Declaration>();

  return useCallback(
    ({
      manufacturerId,
      plantId,
      productId,
    }: {
      manufacturerId: string;
      plantId: string;
      productId: string;
    }) =>
      api({
        url: `/v1/manufacturers/${manufacturerId}/plants/${plantId}/products/${productId}/declarations`,
      }),
    [api],
  );
};

export const useGetReasons = () => {
  const api = useApi<Reason[]>({ method: "GET" });

  return useCallback(
    ({
      manufacturerId,
      plantId,
      productId,
    }: {
      manufacturerId: string;
      plantId: string;
      productId: string;
    }) =>
      api({
        url: `/v1/manufacturers/${manufacturerId}/plants/${plantId}/products/${productId}/declarations/reasons`,
      }),
    [api],
  );
};

export const usePostDeclarations = () => {
  const api = useApi<Declaration[]>({ method: "POST" });

  return useCallback(
    ({
      manufacturerId,
      plantId,
      productId,
      reasonId,
      programOperatorId,
    }: {
      manufacturerId: string;
      plantId: string;
      productId: string;
      reasonId: string;
      programOperatorId: string;
    }) =>
      api({
        url: `/v1/manufacturers/${manufacturerId}/plants/${plantId}/products/${productId}/declarations`,
        data: { reason_id: reasonId, program_operator_id: programOperatorId },
      }),
    [api],
  );
};

const useDocumentAccessToken = () => {
  const api = useApi<string>({ method: "POST" });

  return useCallback(
    async ({
      manufacturerId,
      plantId,
      productId,
      documentId,
    }: {
      manufacturerId: string;
      plantId: string;
      productId: string;
      documentId: string;
    }) =>
      await api({
        url: `/v1/manufacturers/${manufacturerId}/plants/${plantId}/products/${productId}/declarations/documents/${documentId}/access-token`,
      }),
    [api],
  );
};

export const useDownloadDocument = () => {
  const tokenResolver = useDocumentAccessToken();

  return useMutation({
    mutationFn: async ({
      manufacturerId,
      plantId,
      productId,
      documentId,
      documentName,
    }: {
      manufacturerId: string;
      plantId: string;
      productId: string;
      documentId: string;
      documentName: string;
    }) => {
      const token = await tokenResolver({
        manufacturerId,
        plantId,
        productId,
        documentId,
      });

      const url = apiLink(
        `/v1/manufacturers/${manufacturerId}/plants/${plantId}/products/${productId}/declarations/documents/${documentId}?token=${token}`,
      );

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", documentName);

      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
    },
  });
};

export const useUploadDocument = () => {
  const api = useApi<void>({
    method: "POST",
    headers: { "Content-Type": "multipart/form-data" },
  });

  return useMutation({
    mutationFn: ({
      manufacturerId,
      plantId,
      productId,
      declarationId,
      token,
      file,
    }: {
      manufacturerId: string;
      plantId: string;
      productId: string;
      declarationId: string;
      token: string;
      file: File;
    }) => {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("token", token);

      return api({
        url: `/v1/manufacturers/${manufacturerId}/plants/${plantId}/products/${productId}/declarations/${declarationId}/document`,
        data: formData,
      });
    },
  });
};

export const useGetLciaResults = () => {
  const api = useApi<LciaResults>({ method: "GET" });

  return useCallback(
    ({
      manufacturerId,
      plantId,
      productId,
    }: {
      manufacturerId: string;
      plantId: string;
      productId: string;
    }) =>
      api({
        url: `/v1/manufacturers/${manufacturerId}/plants/${plantId}/products/${productId}/lcia-results`,
      }),
    [api],
  );
};
