import { Impact } from "../api/types";

export const mergeImpact = (...impacts: (Impact[] | null)[]): Impact[] => {
  const merge = (a: Impact[], b: Impact[]): Impact[] => {
    const accMap = new Map<string, Impact>();

    // Add all elements from 'b' to the map
    b.forEach((item) => accMap.set(item.indicator_name, { ...item }));

    // Merge elements from 'a' into the map
    a.forEach((item) => {
      if (accMap.has(item.indicator_name)) {
        const existing = accMap.get(item.indicator_name)!;
        existing.value += item.value;
        if (existing.unit !== item.unit) {
          console.warn(
            `Unit mismatch for indicator ${item.indicator_name}, ${existing.unit} !== ${item.unit}`,
          );
        }
      } else {
        accMap.set(item.indicator_name, { ...item });
      }
    });

    // Convert map values back to array
    return Array.from(accMap.values());
  };

  return impacts.map((x) => x ?? []).reduce((acc, cur) => merge(acc, cur), []);
};

export const impactByIndicator = (
  impacts: Impact[],
  indicator: Impact["indicator_name"],
): Impact => {
  const impact = impacts.find((item) => item.indicator_name === indicator);
  if (!impact) {
    console.warn(`Indicator ${indicator} not found in impact array`);
    return {
      indicator_name: indicator,
      value: 0,
      unit: "",
    };
  }

  return impact;
};

export const coreIndicators: Set<Impact["indicator_name"]> = new Set([
  "GWP-total",
  "AP",
  "POCP",
  "ADPE",
  "ADPF",
  "EP-freshwater",
  "WDP",
  "ODP",
]);

export const filterImpact = (
  impacts: Impact[],
  indicators: Set<Impact["indicator_name"]>,
): Impact[] => {
  return impacts.filter((impact) => indicators.has(impact.indicator_name));
};
