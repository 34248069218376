import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Product } from "../../api/types";
import { StatusIndicator } from "../../components/StatusIndicator";

export const useProductStatus = (status: Product["status"]) => {
  const { t } = useTranslation();

  return useMemo(() => {
    switch (status) {
      case "incomplete":
        return t("Data missing");
      case "draft":
        return t("Ready for verification");
      case "under-review":
        return t("Awaiting verification");
      case "completed":
        return t("EPD published & verified");
    }
  }, [t, status]);
};

export const ProductStatusIndicator = ({ status }: { status: Product["status"] }) => {
  return (
    <StatusIndicator
      small
      text={useProductStatus(status)}
      missing={status === "incomplete"}
      waiting={status === "under-review"}
      ready={status === "draft"}
      done={status === "completed"}
    />
  );
};
